
import { useEffect, useRef, useState } from 'react';
import './tableTask.css';
import './tableTaskDark.css';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Tr from './tr';
import { useNavigate } from 'react-router';
import TaskMenegerHeader from './taskMenegerHeader/taskMenegerHeader';
import ForDekstopTable from './forDekstopTable/forDekstopTable';
import ForMobileAndTable from './ForMobileAndTable/forMobileAndTable';
import NoTaskPage from '../noTaskPage/noTaskPage';
import NothingFoundSearchTask from '../Nothing found task/NothingFoundSearchTask';

export default function TaskTable({ receivedMessages, handleDelet, delLoad, update, handleGetNotify, load, onlineUsers, sendMessage }) {

  const [data, setData] = useState([]);

  const [toggleName, setToggleName] = useState("Сортировка")
  const getAllTaskRed = useSelector(compID => compID.getAllTaskReducer)
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [filterFuncm, setfilterFunc] = useState({});
  const [searchValue, setsearchValue] = useState("")
  const [isAscendingfilter, setIsAscendingfilter] = useState(true); // Состояние для отслеживания порядка сортировки
  const [isAscendingDate, setisAscendingDate] = useState(true); // Состояние для отслеживания порядка сортировки
  const [isAscendingStatus, setIsAscendingStatus] = useState(true); // Состояние для отслеживания порядка сортировки
  const [isAscendingPrice, setIsAscendingPrice] = useState(false); // Состояние для отслеживания порядка сортировки

  // Здесь мы получаем информацию через сокетов и добавляем на состояние
  // useEffect(() => {
  //   setData(receivedMessages);
  // }, [receivedMessages]);

  // здесь мы делаем фильтрацию по приоритета
  const filter = () => {
    const priorityOrder = {
      Критический: 0,
      Важно: 1,
      Срочно: 2,
      Низший: 3,
    };
  
    // Используем функцию обновления состояния для переключения порядка сортировки
    setIsAscendingfilter(prevIsAscendingfilter => {
      // Определяем новое состояние сортировки на основе предыдущего состояния
      const newAscendingfilter = !prevIsAscendingfilter;
  
      const sortedData = [...getAllTaskRed.users].sort((a, b) => {
        const priority1 = priorityOrder[a.alltask[0]?.prioritet] || 0; // Обработка возможных значений undefined
        const priority2 = priorityOrder[b.alltask[0]?.prioritet] || 0; // Обработка возможных значений undefined
  
        // Используем новое состояние для определения порядка сортировки
        return newAscendingfilter ? priority1 - priority2 : priority2 - priority1;
      });
  
      setData(sortedData);
      getAllTaskRed.users = sortedData;
      setMenuOpen(!isMenuOpen);
  
      // Возвращаем новое состояние для обновления
      return newAscendingfilter;
    });
  };
  

  // filterDate
  // здесь мы фильтруем по дате
  const filterBydate = () => {
    // Используем функцию обновления состояния для переключения порядка сортировки
    setisAscendingDate(prevIsAscendingDate => {
      // Определяем новое состояние сортировки на основе предыдущего состояния
      const newAscendingDate = !prevIsAscendingDate;
  
      const sortedData = [...getAllTaskRed.users].sort((a, b) => {
        const dateA = new Date(a.alltask[0].endDate);
        const dateB = new Date(b.alltask[0].endDate);
  
        // Используем новое состояние для определения порядка сортировки
        return newAscendingDate ? dateA - dateB : dateB - dateA;
      });
  
      setData(sortedData);
      getAllTaskRed.users = sortedData;
      setMenuOpen(!isMenuOpen);
  
      // Возвращаем новое состояние для обновления
      return newAscendingDate;
    });
  };
  
  // здесь мы делаем фильтрацию для статуса
  const filterByStatus = () => {
    const priorityOrder = {
      Новая: 0,
      Возвращена: 1,
      Выполняется: 2,
      Выполнена: 3,
      Отменено: 4,
      Закрыта: 5,
    };
  
    // Используем функцию обновления состояния для переключения порядка сортировки
    setIsAscendingStatus(prevIsAscendingStatus => {
      // Определяем новое состояние сортировки на основе предыдущего состояния
      const newAscendingStatus = !prevIsAscendingStatus;
  
      const sortedData = [...getAllTaskRed.users].sort((a, b) => {
        const status1 = priorityOrder[a.alltask[0]?.status] || 0; // Обработка возможных значений undefined
        const status2 = priorityOrder[b.alltask[0]?.status] || 0; // Обработка возможных значений undefined
  
        // Используем новое состояние для определения порядка сортировки
        return newAscendingStatus ? status1 - status2 : status2 - status1;
      });
  
      setData(sortedData);
      getAllTaskRed.users = sortedData;
      setMenuOpen(!isMenuOpen);
  
      // Возвращаем новое состояние для обновления
      return newAscendingStatus;
    });
  };
  


  // filter
  // через эту функцию выбирается фильтрация
  const handleSelect = (e) => {

    if (e == "Приоритет") {


      filter(e)
      setToggleName('Приоритет')
      setMenuOpen(!isMenuOpen);

    } else if (e == "Дата") {
      filterBydate(e)
      setToggleName('Дата')
      setMenuOpen(!isMenuOpen);

    } else if (e == "статусу") {
      filterByStatus(e)
      setToggleName('Статусу')
      setMenuOpen(!isMenuOpen);

    }
  }
  // perfomer
  // здесь ищем сотрудника через форму
  const searchByPerfomer = (e) => {
    setsearchValue(e)
    axios.post('/findByInputSearch/api', {
      inputVal: e
    },

    )
      .then(function (response) {
        console.log(response.data.data);
        setData(response.data.data)
        getAllTaskRed.users = response.data.data
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const filterTasks = () => {
    const priorityOrder = {
      Новая: 0,
      Возвращена: 1,
      Выполняется: 2,
      Выполнена: 3,
      Отменено: 4,
      Закрыта: 5,
    };
  
    // Используем функцию обновления состояния для переключения порядка сортировки
    setIsAscendingStatus(prevIsAscendingStatus => {
      // Определяем новое состояние сортировки на основе предыдущего состояния
      const newAscendingStatus = !prevIsAscendingStatus;
  
      const sortedData = [...getAllTaskRed.users].sort((a, b) => {
        const status1 = priorityOrder[a.alltask[0]?.status] || 0; // Обработка возможных значений undefined
        const status2 = priorityOrder[b.alltask[0]?.status] || 0; // Обработка возможных значений undefined
  
        // Используем новое состояние для определения порядка сортировки
        return newAscendingStatus ? status1 - status2 : status2 - status1;
      });
  
      setData(sortedData);
      getAllTaskRed.users = sortedData;
      setMenuOpen(!isMenuOpen);
  
      // Возвращаем новое состояние для обновления
      return newAscendingStatus;
    });
  };
  const filterPrice = () => {
    // Используем функцию обновления состояния, чтобы корректно переключать порядок сортировки
    setIsAscendingPrice(prevIsAscendingPrice => {
      // Определяем новое состояние сортировки на основе предыдущего состояния
      const newAscendingPrice = !prevIsAscendingPrice;
  
      // Сортируем данные на основе нового состояния сортировки
      const sortedData = [...getAllTaskRed.users].sort((a, b) => {
        const priceA = a.alltask[0]?.price || 0; // Обработка возможных значений undefined
        const priceB = b.alltask[0]?.price || 0; // Обработка возможных значений undefined
  
        // Используем новое состояние для определения порядка сортировки
        return newAscendingPrice ? priceA - priceB : priceB - priceA;
      });
  
      setData(sortedData);
      getAllTaskRed.users = sortedData;
      setMenuOpen(!isMenuOpen);
  
      // Возвращаем новое состояние для обновления
      return newAscendingPrice;
    });
  };
  
  
  useEffect(() => {
    // filterTasks()
    setfilterFunc({ filterTasks, filterBydate, filter,filterPrice })
  }, [getAllTaskRed])


  useEffect(() => {

  }, [getAllTaskRed.users, searchValue])
  if (getAllTaskRed.users.length > 0) {
    return (
      <>
        <TaskMenegerHeader searchByPerfomer={searchByPerfomer} handleSelect={handleSelect} searchValue={searchValue} />
   
        {/* {
          (() => {
            if (getAllTaskRed.loading) {
              return <div class="custom-loaderTaskTable"></div>
            }
          })()
        } */}
        <ForDekstopTable getAllTaskRed={getAllTaskRed} update={update} data={data} handleGetNotify={handleGetNotify} onlineUsers={onlineUsers} filterFuncm={filterFuncm} />
        <ForMobileAndTable getAllTaskRed={getAllTaskRed} update={update} data={data} handleGetNotify={handleGetNotify} onlineUsers={onlineUsers} filterFuncm={filterFuncm} />
      </>
    );
  } else if (getAllTaskRed.users.length == 0 && searchValue != "") {
    return <NothingFoundSearchTask searchByPerfomer={searchByPerfomer} handleSelect={handleSelect} searchValue={searchValue} />
  }
  else {
    return <NoTaskPage searchByPerfomer={searchByPerfomer} handleSelect={handleSelect} searchValue={searchValue} />
  }


}

