import { useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { validation, passwordValidation } from "./validationFrom/validation";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./registration.css";
import useFetch from "../../../hook/hookFetch/fetchHookPost";
import axios from "axios";

export default function Registration(params) {
  const navigate = useNavigate();

  const email = useRef(null);
  const password = useRef(null);
  const [isLoading, setIsloading] = useState(false);
  const [isValidPassword, setisValidPassword] = useState(false);
  const [isValid, setisValid] = useState(false);

  // send handlePosRegistration to server
  //отправляем сервер для регистрации
  const handlePosRegistration = (e) => {
    e.preventDefault();




    // validation email and password onclick
    //проверяем валидацию формы
    if (validation(e.target.email) && isValidPassword) {
      setIsloading(true)
      axios
        .post("/registration/api", {
          name: e.target.name.value,
          email: e.target.email.value,
          gender: e.target.gender.value,
          password: e.target.password.value,
          tel: e.target.tel.value,
        })
        .then(function (response) {
          console.log(response);
         
          swal(
            "отлично!",
            "пожалуйста Подтвердите свой адрес электронной почты чтобы войти в свою учётную запись!",
            "success"
          );

          //reset setttings------------------------------------------------------
          e.target.name.value = "";
          e.target.email.value = "";
          e.target.gender.value = "";
          e.target.password.value = "";
          e.target.tel.value = "";
          setisValidPassword(false);
          email.current.style.outline = "1px solid #8692A6";
          password.current.style.outline = "1px solid #8692A6";
          setIsloading(false)
        })
        .catch(function (error) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsloading(false)
        });

      //============================================================================
    } else {
      toast.error("пожалуйста Заполните необходимые поля!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    //validation email  onclick and password onclick
    //каждый нажать мы проверяем валидацию
    if (validation(e.target.email)) {
      email.current.style.outline = "1px solid green";
      setisValid(false);
    } else {
      email.current.style.outline = "1px solid red";
      setisValid(true);
      toast.error("Заполните поле электронной почты!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    // validation password  onclick
    //здесь мы только проверяем пароль
    if (isValidPassword) {
      password.current.style.outline = "1px solid green";
      setisValid(false);
    } else {
      password.current.style.outline = "1px solid red";
      setisValid(true);
      toast.error("Заполните поле пароль!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  //-----------------------------------------------------------------------

  //onchange validation meail
  //здесь мы проверяем проверку нажатия клавиш
  const validateFrom = () => {
    if (validation(email.current)) {
      email.current.style.outline = "1px solid green";
    } else {
      email.current.style.outline = "1px solid red";
    }
  };
  //=============================

  //onchange validation password

  const validatePassword = () => {
    if (passwordValidation(password.current)) {
      password.current.style.outline = "1px solid green";
      setisValidPassword(true);
    } else {
      password.current.style.outline = "1px solid red";
      setisValidPassword(false);
    }
  };

  //==============================================================
  return (
    <div className="RegisterCont">
      <div className="imgCont">
        <img src={"https://app.shopstat.ru/static/svg/LoginImage.svg"} alt="" />
      </div>
      <div className="formCont">
        <div className="backLink">
          <span className="material-symbols-outlined">arrow_back_ios</span>
          <NavLink to="/login">назад</NavLink>
        </div>
        <form onSubmit={handlePosRegistration}>
          <h1>Регистрация аккаунта</h1>
          <p>Станьте участником и наслаждайтесь эксклюзивными акциями.</p>
          <label htmlFor="">Полное имя</label>
          <input type="text" name="name" />
          <label htmlFor="">Адрес электронной почты</label>
          <input type="text" name="email" ref={email} onChange={validateFrom} />
          <label htmlFor="">Пол</label>
          <select name="gender" id="" n>
            <option defaultValue="мужской">мужской</option>
            <option defaultValue="женский">женский</option>
          </select>
          <label htmlFor="">Пароль</label>
          <input
            type="password"
            name="password"
            ref={password}
            onChange={validatePassword}
          />
          <label htmlFor="">Ваш телефонный номер</label>
          <input type="tel" name="tel" placeholder="79200200000"  required={true}/>

          <div className="remember"></div>

          {isLoading ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Loading...</span>
            </Button>
          ) : (
            <button type="submit">Вход</button>
          )}
          <span className="Donthaveanaccount">
            У вас есть аккаунт? <NavLink to="/login">Подпишите здесь</NavLink>
          </span>
        </form>

        <ToastContainer />
      </div>
    </div>
  );
}
