import { createStore, applyMiddleware,combineReducers } from 'redux';



import thunk from 'redux-thunk';
import userReducerCompanyChecked from '../reducer/checkedCompany/reducer';
import userReducerCompany from '../reducer/getCompany/reducer';
import getMaydataSatateReducer from '../reducer/getMydATA/reducer';
import rootReducer from '../reducer/postFetcReducer/reducer';
import getDefaultCompanyReducer from '../reducer/setDefaultCompany/reducer';
import getMyCompanyAPIReducer from '../reducer/myCompanyAPI/reducer';
import getMayInfoReducer from '../reducer/getmyinfo/reducer';
import useChangeDarkReducer from '../reducer/darkMode/reducer';
import userReducerEditCompany from '../reducer/editCompoany/reducer';
import getMyImageReducer from '../reducer/getMyimg/reducer';
import checkedSessionReducer from '../reducer/getCheckedCompanySession/reducer';
import adminReducer from '../reducer/getAdminInfo/reducer';
import getAllCompanyForAdminReducer from '../reducer/getAllCompanyForAdmin/reducer';
import getAllUsersForAdminReducer from '../reducer/getAllUsersForAdmin/reducer';
import getAllAdminReducer from '../reducer/getAllAdmin/reducer';
import getWaldberisListReducer from '../reducer/getwildberrieslIST/reducer';
import getOzonisListReducer from '../reducer/ozonGetProductLIst/reducer';
import ToggleDarkModeReducer from '../reducer/toggleDarkMode/reducer';
import AllOnlineUsersReducer from '../reducer/onlineUsers/reducer';
import getAllTaskReducer from '../reducer/getTaskall/reducer';
import ToggleMenuReducer from '../reducer/toggleMenu/reducer';
// import userReducerGETallUsers from '../reducer/getallusers/reducer';
import getOnlineUserViaRouterReducer from '../reducer/getOnlineUserviaRouter/reducer';
import PerexodeChatMenuReducer from '../reducer/perexodChatMenu/reducer';
import getChatItemReducer from '../reducer/getChatItem/reducer';
import userReducerMessageItem from '../reducer/getMessageItem/reducer';
import otherMenuOptionsForMessageItemsReducer from '../reducer/otherMenuOptionsForMessageItems/reducer';
import getChatInfoByIDReducer from '../reducer/getChatInfoByID/reducer';
import userReducerGerCurrentSubMessage from '../reducer/getCurrentSubMessage/reducer';
import TradeReducer from '../reducer/trade/reducer';
import userReducerGetWorkersForChat from '../reducer/получить сотрудников для чата/reducer';
import { composeWithDevTools } from 'redux-devtools-extension';



//Здесь мы получаем reduceri
const roots = combineReducers({

  checkedSessionReducer,
  userReducerEditCompany,
  getMyImageReducer,
  getMayInfoReducer,
  rootReducer,
  getMyCompanyAPIReducer,
  getMaydataSatateReducer,
  userReducerCompany,
  useChangeDarkReducer,
  ToggleDarkModeReducer,
  adminReducer,
  getOzonisListReducer,
  getWaldberisListReducer,
  getAllCompanyForAdminReducer,
  getAllUsersForAdminReducer,
  getAllAdminReducer,
  getDefaultCompanyReducer,
  getAllTaskReducer,
  userReducerGerCurrentSubMessage,
  userReducerMessageItem,
  getChatInfoByIDReducer,
  userReducerGetWorkersForChat,
  getChatItemReducer,
  getOnlineUserViaRouterReducer,
  AllOnlineUsersReducer,
  otherMenuOptionsForMessageItemsReducer,
  TradeReducer,
  PerexodeChatMenuReducer,
  ToggleMenuReducer,
  userReducerCompanyChecked,


})


// Объединяем middleware и devtools с помощью compose
const composedEnhancers = composeWithDevTools(applyMiddleware(thunk));

// Создаем стор с объединенными редюсерами и энхансерами
const store = createStore(roots, composedEnhancers);

export default store;