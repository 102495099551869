import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getMyCompany } from "../../../redux/reducer/getCompany/action/action";
import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { checkedCopmany } from "../../../redux/reducer/checkedCompany/action/action";
import { fetchUsers } from "../../../redux/reducer/postFetcReducer/action/action";
import { getCompanyForChangeAPI } from "../../../redux/reducer/myCompanyAPI/action/action";
import DeleteCompany from "../../page/addCompany/deleteCompany/delCompany";
import { getmyinfoAction } from "../../../redux/reducer/getmyinfo/action/action";
import EditCompany from "../../page/addCompany/editCompany/editcopnay";
import { checkedSessionAction } from "../../../redux/reducer/getCheckedCompanySession/action/action";
import CheckedRadioBTN from "../checkedBtn/checkedrdaio";
import ByAxios from "../../../hook/hookFetch/axios";
import "./changeCompanyLight.css"
import "./changeCompanyDark.css"
import sockets from "../../../socket/socket";

const socket = sockets;


export default function ChangeCompanyDrower() {
    const getDarkModoe = useSelector(darkModes => darkModes);
    const [isMobile, setIsMobile] = useState(false);
    const [countTask, setCountTask] = useState([]);
    const [countMessage, setMessage] = useState([]);
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [checkedINdexCopmany, setcheckedINdexCopmany] = useState(0);
    const [value, setValue] = useState('0');
    const [searchCompanyVal, setsearchCompanyVal] = useState('');
    const getAllCompany = useSelector(company => company.userReducerCompany.users);
    const checked = useSelector(company => company.userReducerCompanyChecked);
    const getDefaulyCheckedSession = useSelector(company => company.checkedSessionReducer);
    const getAllCompanyRed = useSelector(company => company);
    const myinfo = useSelector(myinfo => myinfo);
    const myIDs = myinfo.getMayInfoReducer.myinfo._id
    let [data, error, loading, getData] = ByAxios("/auth/login/api");



    useEffect(() => {
        setIsOpen(false);
        getData()
    }, [])
    // console.log(getDark.useChangeDarkReducer);
    // stanum enq @nkerutyun@ @st szbanakan @nterulu hamar (bydefault)

    useEffect(() => {
        dispatch(getCompanyForChangeAPI("/changeapi/api"));
        dispatch(checkedSessionAction("/getCheckedCompanySession"));
        dispatch(fetchUsers())
        dispatch(getmyinfoAction("/registration/v2/api"))
        dispatch(getMyCompany("/mycopany/getallcompany"))
        dispatch(fetchUsers())

        if (getAllCompany.data) {
            dispatch(getCompanyForChangeAPI("/changeapi/api"));
            axios({
                method: 'get',
                url: '/mycopany/getallcompany',

            })
                .then(function (response) {

                    dispatch(checkedCopmany(response.data.data[0].companyId))
                    setValue("0")



                });
        }


    }, [dispatch])


    const changeCompanyID = (companyId, index) => {

        setcheckedINdexCopmany(index)

        setShow(true)
        dispatch(checkedCopmany(companyId))
        if (checked) {
            axios({
                method: 'get',
                url: `/mycopany/changechecked?checked=${companyId}`,

            }).then(rezult => {
                setShow(false)
                dispatch(getCompanyForChangeAPI("/changeapi/api"));

            }).catch(error => {
                setShow(false)

            })
        }

    }
    // useEffect(() => {
    //     dispatch(getCompanyForChangeAPI("/changeapi/api"));
    //     dispatch(getMyCompany("/mycopany/getallcompany"))
    //     dispatch(fetchUsers())

    // }, [dispatch, show])


    const handleGet = () => {
        dispatch(getCompanyForChangeAPI("/changeapi/api"));
        dispatch(getMyCompany("/mycopany/getallcompany"))
    }



    const getCompanyAndChange = () => {
        dispatch(getMyCompany("/mycopany/getallcompany"))
        dispatch(getCompanyForChangeAPI("/changeapi/api"))
    }

    useEffect(() => {
        if (searchCompanyVal !== "") {
            dispatch(getMyCompany("/mycopany/getallcompanySearch", searchCompanyVal))

        } else {
            dispatch(getMyCompany("/mycopany/getallcompany", searchCompanyVal))
        }
    }, [searchCompanyVal])



    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);


    // for mobile and dekstop queries for toggleBTN className
    useEffect(() => {
        const handleMediaQueryChange = (event) => {
            setIsMobile(event.matches);
        };

        // Create a media query for max-width: 617px
        const mediaQuery = window.matchMedia('(max-width: 617px)');

        // Initial check for the media query
        setIsMobile(mediaQuery.matches);

        // Add a listener for changes to the media query
        mediaQuery.addListener(handleMediaQueryChange);

        // Clean up the listener when the component unmounts
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };

    }, [isMobile]);
    const svgRotate = useRef();

    useEffect(() => {
        if (isOpen === true && svgRotate.current) {
            svgRotate.current.style.transform = "rotate(180deg)";

        } else if (svgRotate.current) {
            svgRotate.current.style.transform = "rotate(0)";

        }
        if (svgRotate.current) {
            svgRotate.current.style.transition = "0.3s";
        }
    }, [isOpen]);




    useEffect(() => {
        // Функция для получения данных
        function getCounterSetForListOfCompanies() {
            axios.get('/getcounterSetforlistNotifyofCompanies/api')
                .then(function (response) {
                    setCountTask(response.data);
                })
                .catch(function (error) {
                    console.error(error);
                });
        }

        // Получаем данные при первом рендере и при получении нового сообщения
        getCounterSetForListOfCompanies();
        socket.on('getNotify', getCounterSetForListOfCompanies);

        // Очистка эффекта
        return () => {
            socket.off('getNotify', getCounterSetForListOfCompanies);
        };
    }, [isOpen]); // Зависимость isOpen определяет, когда эффект запускается

    useEffect(() => {
        // Функция для получения данных
        function getCounterSetForListOfCompanies() {
            axios.get('/getcounterSetforChats/api')
                .then(function (response) {
                    setMessage(response.data);


                })
                .catch(function (error) {
                    console.error(error);
                });
        }

        // Получаем данные при первом рендере и при получении нового сообщения
        getCounterSetForListOfCompanies();
        console.log(countMessage);

        socket.on('updateCreatMessage', getCounterSetForListOfCompanies);
        socket.on('updateSubMessage', getCounterSetForListOfCompanies);
        socket.on('deleteChat', getCounterSetForListOfCompanies);

        // Очистка эффекта
        return () => {
            socket.off('newMessage', getCounterSetForListOfCompanies);
        };
    }, [isOpen]); // Зависимость isOpen определяет, когда эффект запускается

    return (
        <div ref={dropdownRef} className={getDarkModoe.ToggleDarkModeReducer.chekced ? "dropDownForChangeCompanyContDark" : "dropDownForChangeCompanyContLight"} onClick={getCompanyAndChange}>
            <button className="dropdown-toggle" onClick={() => [toggleDropdown(), handleGet()]}>
                <p className={getDarkModoe.ToggleDarkModeReducer.chekced ? "isOpenP" : "isOpenPNot"}> {getAllCompanyRed.getMyCompanyAPIReducer.company
                    ? getAllCompanyRed.getMyCompanyAPIReducer.company.sellersname
                    : "Выберите компанию"}</p>

                {
                    isOpen ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" ref={svgRotate}>
                            <path d="M18 9L12 15L6 9" stroke="#5E7DE8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" ref={svgRotate}>
                            <path d="M18 9L12 15L6 9" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                }

            </button>
            {isOpen && (
                <div>
                    <button onClick={() => [navigate("/sellertnewuserpage"), setIsOpen(false)]}>
                        <div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                                </svg>
                            </div>
                            <p> Добавить компанию</p>
                        </div>

                    </button>
                    <div>
                        <label>
                            <input type="text" placeholder="Поиск" onChange={(e) => setsearchCompanyVal(e.target.value)} value={searchCompanyVal} />
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z" fill="#A8A8A8" />
                                    <path d="M18.5304 17.4698C18.2375 17.1769 17.7626 17.1769 17.4697 17.4698C17.1768 17.7626 17.1768 18.2375 17.4697 18.5304L21.4696 22.5304C21.7625 22.8233 22.2374 22.8233 22.5303 22.5304C22.8232 22.2375 22.8232 21.7626 22.5303 21.4697L18.5304 17.4698Z" fill="#A8A8A8" />
                                </svg>
                            </div>
                        </label>
                    </div>
                    <ul className="dropDownForChangeCompanydropdown-menu">
                        {
                            getAllCompany.data?.map((items, index) => {


                                const taskCount = countTask.find(task => task._id === items.companyId) || { taskCount: 0 };
                                const messageCount = countMessage.find(message => message._id.companyID === items.companyId) || { messageCount: 0 };



                                return (
                                    <label htmlFor={`${"chekedCompany"}` + index} className={getDefaulyCheckedSession.checkedSession == items.companyId ? "thisChecked" : "thisCheckedNot"} key={index} onClick={() => changeCompanyID(items.companyId, index)}>
                                        <li>
                                            <div className="Frame625952">

                                                {
                                                    (getDefaulyCheckedSession.checkedSession == items.companyId) ?
                                                        <CheckedRadioBTN index={index} checked={true} />
                                                        :
                                                        <CheckedRadioBTN index={index} checked={false} />
                                                }

                                                <p>{items.sellersname}</p>
                                            </div>
                                            <div className="classNameEditDelCont">
                                                {
                                                    (() => {
                                                        if (items.adminID == myIDs) {
                                                            return (
                                                                <>
                                                                    <EditCompany items={items} />
                                                                    <DeleteCompany items={items} />
                                                                </>
                                                            )
                                                        }
                                                    })()
                                                }

                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <div>
                                                    <p>Чат:</p>
                                                    <p>{messageCount?.unreadCount ? messageCount?.unreadCount : 0}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <p>События:</p>
                                                    <p>{taskCount.taskCount || 0}</p> {/* Используем значение taskCount */}
                                                </div>
                                            </div>
                                        </li>
                                    </label>
                                )
                            })
                        }

                    </ul>
                </div>

            )}
        </div>
    )
}   