import React, { useEffect } from 'react';
import { useToast } from '@chakra-ui/react'
import swal from "sweetalert";
// Функция для преобразования base64 строки в uint8Array
function urlBase64ToUint8Array(base64String) {
    
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/\_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// Функция для подписки на push-уведомления
function subscribeToPushNotifications(registration) {
  const publicVapidKey = 'BJytLiV4cCLO3cgjFJjBJn-OQiaJajpbBSvRZhUHxjD2C4ugy0j0PfLt3w5HC2drkut3Bh6s9l5V_uuoRCx2R2o';  // Ваш публичный VAPID-ключ

  // Преобразуем публичный ключ в формат uint8Array
  const applicationServerKey = urlBase64ToUint8Array(publicVapidKey);

  // Подписка на push-уведомления
  registration.pushManager.subscribe({
    userVisibleOnly: true, // Обязательно для push-уведомлений
    applicationServerKey: applicationServerKey
  })
  .then((subscription) => {

    console.log('Подписка на push уведомления:', subscription);

    // Отправляем подписку на сервер для дальнейшей работы
    fetch('/subscribe', {
      method: 'POST',
      body: JSON.stringify(subscription),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.ok) {
        console.log('Подписка успешно отправлена на сервер');
      } else {
        console.error('Ошибка отправки подписки на сервер');
      }
    });
  })
  .catch((error) => {
    console.error('Ошибка подписки на уведомления:', error);
  });
}

const PushNotificationSubscriber = () => {
   
    const toast = useToast()
    const [deferredPrompt, setDeferredPrompt] = React.useState(null);
  useEffect(() => {
    // Проверяем, поддерживает ли браузер Service Worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then((registration) => {
          console.log('Service Worker зарегистрирован:', registration);
          toast({
            position:"bottom-right",
            title: 'Service Worker зарегистрирован:',
            description: "Service Worker зарегистрирован:",
            status: 'success',
            duration: 2000,
            isClosable: true,
          })
          // Подписка на push-уведомления
          subscribeToPushNotifications(registration);
        })
        .catch((error) => {
          console.error('Ошибка регистрации Service Worker:', error);
          toast({
            position:"bottom-right",
            title: 'Ошибка регистрации Service Worker:',
            description: "Ошибка регистрации Service Worker:",
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        });
    } else {
      console.log('Service Worker не поддерживается в этом браузере');
      toast({
        position:"bottom-right",
        title: 'Service Worker не поддерживается в этом браузере:',
        description: "Service Worker не поддерживается в этом браузере:",
        status: 'warning',
        duration: 2000,
        isClosable: true,
      })
    }
  }, []);


};



export default React.memo(PushNotificationSubscriber);
