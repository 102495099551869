// serviceWorkerRegistration.js
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  window.location.hostname === '[::1]' ||
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);

        navigator.serviceWorker.ready.then(() => {
          console.log('Это приложение обслуживается кэшем Service Worker.');
        });
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) return;

        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              console.log('Новое обновление доступно.');

              // Уведомить пользователя о доступности новой версии
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }

              // Предложить обновление с модальным окном или кастомным уведомлением
              showUpdateNotification();
            } else {
              console.log('Контент кэширован для оффлайн-использования.');
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };

      // Подписка на push-уведомления
      subscribeToPushNotifications(registration);
    })
    .catch((error) => {
      console.error('Ошибка при регистрации Service Worker:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl, { headers: { 'Service-Worker': 'script' } })
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('Нет соединения с интернетом. Приложение работает в оффлайн-режиме.');
    });
}

function subscribeToPushNotifications(registration) {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Разрешение на уведомления получено.');

      registration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: '<Ваш VAPID Public Key>',
        })
        .then((subscription) => {
          console.log('Подписка на push уведомления:', subscription);
        })
        .catch((error) => {
          console.error('Ошибка при подписке на push уведомления:', error);
        });
    } else {
      console.log('Пользователь отклонил разрешение на уведомления');
    }
  });
}

function showUpdateNotification() {
  // Модальное окно или кастомное уведомление
  const updateNotification = window.confirm(
    'Доступна новая версия приложения. Хотите обновить сейчас?'
  );

  if (updateNotification) {
    // Если пользователь подтвердил обновление
    window.location.reload();
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error('Ошибка при отмене регистрации Service Worker:', error);
      });
  }
}
