import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import { fetchUsers } from "../../../../redux/reducer/postFetcReducer/action/action";

export default function ChangeDirector({ creaTorTask, setcreaTorTask, onlineUsers, adminInfo }) {

  const dispatch = useDispatch();
  // здесь не получаем всех сотрудников на этой компании
  const gerChekcedCompany = useSelector((allWorkers) => allWorkers);
  const [users, setUsers] = useState([]);
  const [performer, setperformer] = useState("");
  const openModalRef = useRef(null)
  const getDarkModoe = useSelector(darkModes => darkModes);
  let onlineuser = useSelector(onlineUsers => onlineUsers)
  // здесь мы добавляем на состояние всех сотрудников




  useEffect(() => {

    setUsers(gerChekcedCompany.rootReducer.users.data);
  }, []);

  useEffect(() => {

    // This effect will run when gerChekcedCompany.getMayInfoReducer.myinfo._id changes.
    if (gerChekcedCompany.getMayInfoReducer.myinfo._id == creaTorTask) {

      setperformer(gerChekcedCompany.getMayInfoReducer.myinfo);
    } else if (adminInfo._id == creaTorTask) {
      setperformer(adminInfo);
      console.log(performer);
    }
    else {
      for (let i = 0; i < users.length; i++) {
        if (users.workerID === creaTorTask) {
          setperformer(users);
        }

      }
    }
    console.log(adminInfo,"adminInfoadminInfoadminInfo");
    
  }, [creaTorTask]);




  // После нажатия кнопку добавляем нового сотрудника и обновляем состояние
  const perfomer = (e) => {
    setperformer(e)
    setcreaTorTask(e.workerID)
  };
  // здесь ищем сотрудника по номеру телефона и имени
  const searchPerfomer = (e) => {

    const regexPattern = new RegExp(e, 'i');
    let x = gerChekcedCompany.rootReducer.users.data.filter(item => regexPattern.test(item.name) || regexPattern.test(item.tel))
    setUsers(x)
    if (e == "") {
      dispatch(fetchUsers())
      setUsers(gerChekcedCompany.rootReducer.users.data)
    }

  }

  const openModal = () => {
    openModalRef.current.style.display = "flex"
    setUsers(gerChekcedCompany.rootReducer.users.data)
  }

  const closeModal = () => {
    openModalRef.current.style.display = "none"
  }

  useEffect(() => {
    openModalRef.current.style.display = "none"
  }, [])
  console.log( onlineuser.AllOnlineUsersReducer.allOnlineUsers);
  
  return (
    <>
      <div id="myModalPerforem" ref={openModalRef} className={getDarkModoe.ToggleDarkModeReducer.chekced ? "modalPerfomerDark" : "modalPerfomer"}>


        <div className="modal-contentPerfomer">
          <div class="modal-headerPerfomer">
            <div>
              <p>Изменить постановщика</p>


              <div onClick={closeModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M1 1L15 15M1.00003 15L8.00003 8L15 1" stroke="#393939" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </div>
            </div>
            <label htmlFor="searchPerformer">

              <input type="text" placeholder="Поиск" onChange={(e) => searchPerfomer(e.target.value)} id="searchPerformer" />
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z" fill="#A8A8A8" />
                  <path d="M18.5304 17.4698C18.2375 17.1769 17.7626 17.1769 17.4697 17.4698C17.1768 17.7626 17.1768 18.2375 17.4697 18.5304L21.4696 22.5304C21.7625 22.8233 22.2374 22.8233 22.5303 22.5304C22.8232 22.2375 22.8232 21.7626 22.5303 21.4697L18.5304 17.4698Z" fill="#A8A8A8" />
                </svg>
              </div>
            </label>
          </div>
          <div className="modal-bodyPerfomer">
            <div className="executorCont">
              {users?.map(
                (item, index) => {

                  return (
                    <div
                      key={index}
                      className="executor"
                      onClick={(e) => perfomer(item)}

                    >
                      <div>
                        {
                          (() => {
                            if (item.workerID == performer.workerID) {
                              return <div className="chekedDivTrue">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                                  <path d="M1.94635 3.96898C1.65345 3.67608 1.17858 3.67608 0.885686 3.96898C0.592792 4.26187 0.592792 4.73674 0.885686 5.02963L3.60804 7.75199C3.90093 8.04488 4.3758 8.04488 4.6687 7.75199L11.113 1.30767C11.4059 1.01478 11.4059 0.539907 11.113 0.247014C10.8201 -0.0458796 10.3452 -0.0458796 10.0524 0.247014L4.13837 6.161L1.94635 3.96898Z" fill="white" />
                                </svg>
                              </div>
                            } else {
                              return <div className="chekedDivFalse"></div>
                            }
                          })()
                        }

                        {
                          onlineuser.AllOnlineUsersReducer.allOnlineUsers?.includes(item.workerID) ?
                            <Avatar size="xs" name="Christian Nwamba" src={item.img ? `/${item.img}` : "https://bit.ly/code-beast"} >
                              <AvatarBadge boxSize='0.9em' bg='green.500' />
                            </Avatar>
                            :
                            <Avatar size="xs" name="Christian Nwamba" src={item.img ? `/${item.img}` : "https://bit.ly/code-beast"} />
                        }
                        <p className="perfName">{item.name}</p>
                        <p className="perfTel">{item.tel}</p>
                        {/* <p className="perfEmail"> email: {item.email}</p> */}
                      </div>


                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="modal-footerPerfomer">
            <button onClick={closeModal}>
              <div>

                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 16 16" fill="white">
                  <path d="M1 1L15 15M1.00003 15L8.00003 8L15 1" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </div>
              <div><p>Ок</p></div>
            </button>
          </div>
        </div>

      </div>




      <div className="ChangedirectorEdit">

        {
          (() => {
            if (performer != "") {
              return (
                <div>
                  <p className="forDekstop">Постановщик:</p>
                  {
                    performer.tel ?
                      <div style={{ display: 'flex' }} onClick={() => {
                        if (adminInfo._id == gerChekcedCompany.getMayInfoReducer.myinfo._id) {
                          openModal()

                        }

                      }}>

                        {
                          (() => {
                            if (performer._id) {
                              return onlineuser.AllOnlineUsersReducer.allOnlineUsers?.includes(performer.workerID || performer._id) ?
                                <Avatar size="sm" name="Christian Nwamba" src={window.location.origin + "/" + performer.img} style={{ marginRight: "5px", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)" }} >
                                  <AvatarBadge boxSize='0.9em' bg='green.500' />
                                </Avatar>
                                :
                                <Avatar size="sm" name="Christian Nwamba" src={window.location.origin + "/" + performer.img} style={{ marginRight: "5px", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)" }} />
                            } else if (performer.workerID) {
                              return onlineuser.AllOnlineUsersReducer.allOnlineUsers?.includes(performer.workerID || performer._id) ?
                                <Avatar size="sm" name="Christian Nwamba" src={performer.img} style={{ marginRight: "5px", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)" }} >
                                  <AvatarBadge boxSize='0.9em' bg='green.500' />
                                </Avatar>
                                :
                                <Avatar size="sm" name="Christian Nwamba" src={window.location.origin + "/" + performer.img} style={{ marginRight: "5px", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)" }} />
                            }
                          })()
                        }


                        {
                          performer.name ?
                            <p className="perfName">{performer.name}</p>
                            :
                            <p className="perfName">{performer.tel}</p>
                        }
                        <div >
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path fillRule="evenodd" clipRule="evenodd" d="M8.5 6V3.5H15V6H8.5ZM7.75 7.5H15.75H20.75C21.1642 7.5 21.5 7.16421 21.5 6.75C21.5 6.33579 21.1642 6 20.75 6H16.5V3.35C16.5 2.60444 15.8956 2 15.15 2H8.35C7.60442 2 7 2.60442 7 3.35V6H2.75C2.33579 6 2 6.33579 2 6.75C2 7.16421 2.33579 7.5 2.75 7.5H7.75ZM5.5 10.75C5.5 10.3358 5.16421 10 4.75 10C4.33579 10 4 10.3358 4 10.75V20.15C4 20.8956 4.60444 21.5 5.35 21.5H18.15C18.8956 21.5 19.5 20.8956 19.5 20.15V10.75C19.5 10.3358 19.1642 10 18.75 10C18.3358 10 18 10.3358 18 10.75V20H5.5V10.75ZM10.5 10.75C10.5 10.3358 10.1642 10 9.75 10C9.33579 10 9 10.3358 9 10.75V16.75C9 17.1642 9.33579 17.5 9.75 17.5C10.1642 17.5 10.5 17.1642 10.5 16.75V10.75ZM13.75 10C14.1642 10 14.5 10.3358 14.5 10.75V16.75C14.5 17.1642 14.1642 17.5 13.75 17.5C13.3358 17.5 13 17.1642 13 16.75V10.75C13 10.3358 13.3358 10 13.75 10Z" fill="#DD5E98" />
                            </svg>
                          </div>
                         
                        </div>

                      </div>
                      :
                      ""
                  }
                </div>
              )
            } else {
              return <button className="perfomerOpenModal" onClick={openModal}>Изменить постановщика</button>
            }
          })()
        }
      </div>


      {/* =========================================================== */}


    </>
  )
}