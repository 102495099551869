import { useEffect, useRef, useState } from "react"
import "./notifyToggle.css"
import "./notifyToggleDark.css"
import { useDispatch, useSelector } from "react-redux";
import DrawerTaskNotiify from "../../page/taskManager/drawerTaskNotidy/drawerTaskNotidy";

import axios from "axios";
import OverdueTasks from "../../page/taskManager/overdueTasks/overdueTasks";
import CreatNotifyTask from "../../page/taskManager/creatTaskNotify/creatTaskNotify";
import CommentNotify from "../../page/taskManager/commentNotify/commentNotify";
import notifySong from "./notifySong.wav"
import GlobalNotify from "../../page/taskManager/globalNotify/globalNotify";
import sockets from "../../../socket/socket";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Input
} from '@chakra-ui/react'

const socket = sockets;

export default function NotifyToggle(params) {


  const dropdownRef = useRef(null);
  const getDarkModoe = useSelector(darkModes => darkModes)
  const [room, setRoom] = useState('');
  const [message, setMessage] = useState('');
  const [receivedMessages, setReceivedMessages] = useState([])
  const [delLoad, setDelload] = useState(false)
  const [load, setload] = useState(false)
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [perfomerID, setperfomerID] = useState([]);
  const [isRead, setIsread] = useState(false);
  const [mynotify, setmynotify] = useState([]);
  const [IsReadFilter, setIsReadFilter] = useState([]);
  const [deleteNotifyLoading, setdeleteNotifyLoading] = useState(false)

  const gerChekcedCompany = useSelector((allWorkers) => allWorkers);
  let getCompanyID = useSelector(compID => compID.checkedSessionReducer)
  let getmyinfo = useSelector(compID => compID.getMayInfoReducer)
  const audioRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef()
useEffect(()=>{
  axios({
    method: 'get',
    url: '/notifyConfirm',

  })
    .then(function (response) {
      setload(false)
      setmynotify(response.data.data)
    });
},[])
  useEffect(() => {
    socket.emit('pushmyid', getmyinfo.myinfo._id);
    socket.on('newMessage', (message) => {

      setload(true)


      axios.get('/getTaskfinded/api', {

      })
        .then(function (response) {

          setReceivedMessages(response.data.data);
          setload(false)
        })
        .catch(function (error) {
          console.log(error);
          setload(false)
        })
        .finally(function () {
          // always executed
        });

    });
    // update all task for my data
    socket.on('getAllTaskANDupdate', () => {

      setload(true)


      axios.get('/getAllTaskANDupdate/api', {

      })
        .then(function (response) {

          setReceivedMessages(response.data.data);
          setload(false)
        })
        .catch(function (error) {
          console.log(error);
          setload(false)
        })
        .finally(function () {
          // always executed
        });

    });

    socket.on('getNotify', (msg) => {
      setload(true)
      axios({
        method: 'get',
        url: '/notifyConfirm',

      })
        .then(function (response) {
          setload(false)
          setmynotify(response.data.data)
        });
    });


    return () => {
      socket.off('newMessage');
      socket.off('disconnect');
      socket.off('notification');
      socket.off('getNotify');
      socket.off('getAllTaskANDupdate');

    };
  }, []);

  useEffect(() => {
    let filter = mynotify.filter(item => item.isUnread !== true);
    setIsReadFilter(filter)
  }, [mynotify])

  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };


  // update compID
  useEffect(() => {
    
      
    setReceivedMessages([])
    // socket.emit('joinRoom', getCompanyID.checkedSession);
    socket.emit('getAllTask', { companyIDS: getCompanyID.checkedSession });
    socket.emit('getNotifyEmit');
  }, [])
  // =====



  function update(params) {
    socket.emit('message', { room: getCompanyID.checkedSession });
  }
  const handleGetNotify = () => {
    socket.emit('getNotifyEmit');
  }

  async function deleteAllNotify() {
    setdeleteNotifyLoading(true)
    if (mynotify.length > 0) {
      let notifyID = [];
      mynotify.forEach(not => {

        notifyID.push(not._id)
      });
      axios.post('/deletAllNotification/api', {
        notifyID
      })
        .then(function (response) {
          console.log(response);
          setdeleteNotifyLoading(false)
          socket.emit('getNotifyEmit');
        })
        .catch(function (error) {
          console.log(error);
          setdeleteNotifyLoading(false)
        });
    }


  }


  const filterIsRead = () => {
    setIsread(true)
  }

  const getAllnotify = () => {
    setIsread(false)
  }
  return (
    <div ref={dropdownRef} className={getDarkModoe.ToggleDarkModeReducer.chekced ? "dropdownAllNotifyContDark" : "dropdownAllNotifyCont"}>
      <div className="notifyBtnNav" ref={btnRef} colorScheme='teal' onClick={() => [onOpen(), handleGetNotify()]} >
        {
          mynotify.length > 0 ? (
            <div className="badge">
              {mynotify.length >= 999 ? (
                <p>999+</p>
              ) : (
                <p>{mynotify.length}</p>
              )}
            </div>
          ) : null
        }

        <button>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path d="M13.8658 8.65134C14.3605 13.2213 16.3027 14.6024 16.3027 14.6024H1C1 14.6024 3.55045 12.7888 3.55045 6.44095C3.55045 4.99792 4.08786 3.614 5.04446 2.59362C6.00107 1.57324 7.2985 1 8.65134 1C8.93809 1 9.22238 1.02576 9.50149 1.07608" stroke="#353535" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6022 6.10089C16.0108 6.10089 17.1527 4.95902 17.1527 3.55045C17.1527 2.14188 16.0108 1 14.6022 1C13.1936 1 12.0518 2.14188 12.0518 3.55045C12.0518 4.95902 13.1936 6.10089 14.6022 6.10089Z" stroke="#353535" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.1222 17.1523C9.97272 17.41 9.75823 17.6239 9.50004 17.7725C9.24193 17.9212 8.94931 17.9995 8.65142 17.9995C8.35353 17.9995 8.06091 17.9212 7.8028 17.7725C7.5447 17.6239 7.33012 17.41 7.18066 17.1523" stroke="#353535" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </div>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size={"lg"}

      >
        <DrawerContent  className={getDarkModoe.ToggleDarkModeReducer.chekced ? "notifyDrowerDark" : "notifyDrowerLight"}>
          <DrawerHeader>
            <div className="Headertitle">
              <div>
                <p>Уведомления</p>
              </div>
              <div>
                <div onClick={deleteAllNotify}>
                  <p>Удалить все сообщения</p>
                </div>
                <div onClick={onClose}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.6712 12L7.27521 8.60406C6.90826 8.23711 6.90826 7.64216 7.27521 7.27521C7.64216 6.90826 8.23711 6.90826 8.60406 7.27521L12 10.6712L15.3959 7.27521C15.7629 6.90826 16.3578 6.90826 16.7248 7.27521C17.0917 7.64216 17.0917 8.23711 16.7248 8.60406L13.3288 12L16.7248 15.3959C17.0917 15.7629 17.0917 16.3578 16.7248 16.7248C16.3578 17.0917 15.7629 17.0917 15.3959 16.7248L12 13.3288L8.60406 16.7248C8.23711 17.0917 7.64216 17.0917 7.27521 16.7248C6.90826 16.3578 6.90826 15.7629 7.27521 15.3959L10.6712 12Z" fill="#64748B" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="mode-Tabs">
              <div>
                <div onClick={getAllnotify}>
                  <div>
                    <p>Все</p>
                    <div>
                      <p>{mynotify.length}</p>
                    </div>
                  </div>
                  {
                    isRead ? "" : <span></span>
                  }
                </div>
                <div onClick={filterIsRead}>
                  <div>
                    <p>Непрочитанные</p>
                    <div>
                      <p>{IsReadFilter.length}</p>
                    </div>
                  </div>
                  {
                    isRead ? <span></span> : ""
                  }

                </div>
              </div>
            </div>
            {/* {
              (() => {
                if (deleteNotifyLoading) {
                  return <div class="custom-loaderDeletNotify"></div>

                } else {
                  if (mynotify.length > 0) {
                    return <button className="deleteAllNotify" onClick={deleteAllNotify}> Удалить все уведомления<span class="material-symbols-outlined">clear_all</span> </button>
                  }

                }
              })()
            } */}
          </DrawerHeader>
          <DrawerBody>
            <div className="dropdown-menuNotify">
              <div className="dropdown-menuNotifyCont">

                {
                  (() => {

                    if (load) {
                      return <div class="loadNotify"></div>

                    }
                    if (mynotify.length <= 0) {
                      return <h1 style={{ textAlign: 'center' }}>Нет уведомлений</h1>
                    }
                  })()
                }
                {
                  (() => {
                    if (isRead == false) {
                      return mynotify?.map((item, index) => {
                            //= console.log(item.typenotifyGlobal)                            
                        if (item.typenotifyGlobal === "taskConfirm") {
                          return <DrawerTaskNotiify mynotify={mynotify} lengthNotify={mynotify?.length.toString()} update={update} handleGetNotify={handleGetNotify} item={item} index={index} />
                        } else if (item.typenotifyGlobal == "overdueTasks") {
                          return <OverdueTasks item={item} />
                        }
                        else if (item.typenotifyGlobal == "creatTaskNotifyInfo") {
                          return <CreatNotifyTask item={item} />
                        }
                        else if (item.typenotifyGlobal == "creatCommentNotify") {
                          return <CommentNotify item={item} />
                        }
                        else if (item.typenotifyGlobal == "globalNotifyiInfoTask") {
                          return <GlobalNotify item={item} />
                        }


                      })
                    } else {
                      return IsReadFilter?.map((item, index) => {
                        console.log(item, "IsReadFilter");
                        if (item.typenotifyGlobal === "taskConfirm") {
                          return <DrawerTaskNotiify mynotify={mynotify} lengthNotify={mynotify?.length.toString()} update={update} handleGetNotify={handleGetNotify} item={item} index={index} />
                        } else if (item.typenotifyGlobal == "overdueTasks") {
                          return <OverdueTasks item={item} />
                        }
                        else if (item.typenotifyGlobal == "creatTaskNotifyInfo") {
                          return <CreatNotifyTask item={item} />
                        }
                        else if (item.typenotifyGlobal == "creatCommentNotify") {
                          return <CommentNotify item={item} />
                        }
                        else if (item.typenotifyGlobal == "globalNotifyiInfoTask") {
                          return <GlobalNotify item={item} />
                        }


                      })
                    }
                  })()
                }
                {/* {
                  mynotify?.map((item, index) => {
                        
                    if (item.typenotifyGlobal === "taskConfirm") {
                      return <DrawerTaskNotiify mynotify={mynotify} lengthNotify={mynotify?.length.toString()} update={update} handleGetNotify={handleGetNotify} item={item} index={index} />
                    } else if (item.typenotifyGlobal == "overdueTasks") {
                      return <OverdueTasks item={item} />
                    }
                    else if (item.typenotifyGlobal == "creatTaskNotifyInfo") {
                      return <CreatNotifyTask item={item} />
                    }
                    else if (item.typenotifyGlobal == "creatCommentNotify") {
                      return <CommentNotify item={item} />
                    }
                    else if (item.typenotifyGlobal == "globalNotifyiInfoTask") {
                      return <GlobalNotify item={item} />
                    }

                
                  })
                } */}
              </div>
            </div>
          </DrawerBody>
          <DrawerFooter>
            <Button colorScheme='blue' onClick={onClose}>Закрыть</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

    </div>)
}