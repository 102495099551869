import DownloadButton from "../test/dowNloadYesy"
import FileUploadTes from "../test/upload multiple files with progress indicator/uploadTestProgress"
import "./homeLight.css"

export default function Home() {
    const fileUrl = 'https://example.com/sample-video.mp4';
    const fileName = 'sample-video.mp4';
  
    // return       <DownloadButton downloadUrl={fileUrl} filename={fileName} />

    return (
        <div className="glavimg">
            <img src="/glav.svg" alt="Glav SVG" />
            {/* <FileUploadTes /> */}
        </div>
    )
}