import { useEffect} from "react"
import "./deleteCompanyModal.css"
import "./deleteCompanyModalDark.css"
import { useSelector } from "react-redux";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    useDisclosure,
    Button
} from '@chakra-ui/react'

export default function DeleteCompanyModal({ show, setShow, handleDeleteCompany }) {
    const { isOpen, onOpen, onClose } = useDisclosure();


    const getDarkModoe = useSelector(darkModes => darkModes);

useEffect(()=>{
    if (show === false) {
        onClose()
    }
},[show])
    return (
        <>

            <div onClick={onOpen}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => setShow(true)}>
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.5 6V3.5H15V6H8.5ZM7.75 7.5H15.75H20.75C21.1642 7.5 21.5 7.16421 21.5 6.75C21.5 6.33579 21.1642 6 20.75 6H16.5V3.35C16.5 2.60444 15.8956 2 15.15 2H8.35C7.60442 2 7 2.60442 7 3.35V6H2.75C2.33579 6 2 6.33579 2 6.75C2 7.16421 2.33579 7.5 2.75 7.5H7.75ZM5.5 10.75C5.5 10.3358 5.16421 10 4.75 10C4.33579 10 4 10.3358 4 10.75V20.15C4 20.8956 4.60444 21.5 5.35 21.5H18.15C18.8956 21.5 19.5 20.8956 19.5 20.15V10.75C19.5 10.3358 19.1642 10 18.75 10C18.3358 10 18 10.3358 18 10.75V20H5.5V10.75ZM10.5 10.75C10.5 10.3358 10.1642 10 9.75 10C9.33579 10 9 10.3358 9 10.75V16.75C9 17.1642 9.33579 17.5 9.75 17.5C10.1642 17.5 10.5 17.1642 10.5 16.75V10.75ZM13.75 10C14.1642 10 14.5 10.3358 14.5 10.75V16.75C14.5 17.1642 14.1642 17.5 13.75 17.5C13.3358 17.5 13 17.1642 13 16.75V10.75C13 10.3358 13.3358 10 13.75 10Z" fill="#DD5E98" />
                </svg>
            </div>

            <Modal isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent className={getDarkModoe.ToggleDarkModeReducer.chekced ? "modalDeletCompanyDark" : "modalDeletCompanyLight"}>
                    <ModalHeader>Удалить компанию</ModalHeader>
                    <ModalBody>
                        <div >
                            <div className="modalDeletCompany-content">
                                <div className="modalDeletCompany-header">
                                    <p className="messeageBody">Вы уверены, что хотите удалить компанию?</p>
                                </div>


                            </div>

                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose} mr={3} variant='ghost'>Отменить</Button>
                        <Button colorScheme='blue' borderRadius={"16px"} bg={"#EE6B6B"} onClick={handleDeleteCompany}>
                            Удалить
                        </Button>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>

    )
}