import { useSelector } from "react-redux";
import Tr from "../tr";
import "./forDekstopTableLight.css"
import "./forDekstopTableDark.css"


export default function ForDekstopTable({ getAllTaskRed, update, data, handleGetNotify, onlineUsers,filterFuncm }) {
    const getDarkModoe = useSelector(darkModes => darkModes);

    const handleFilter=(e)=>{
            if (e == "date") {
                filterFuncm.filterBydate()
            }else if (e == "prioritet") {
                filterFuncm.filter()
            }else if (e == "status") {
                filterFuncm.filterTasks()
            }else if (e == "price") {
                filterFuncm.filterPrice()
            }
       
    }
    return (
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "taskTableDekstopContDark" : "taskTableDekstopContLight"}>

            <table id="customers"  >
                <thead >
                    <tr>
                        <th>

                        </th>
                        <th>

                        </th>
                        <th>
                            <div>

                                <p>НАЗВАНИЕ ЗАДАЧИ</p>
                            </div>
                        </th>
                        <th style={{ textAlign: 'center',cursor:"pointer" }}>
                            <div>
                                <p>МАРКЕТПЛЕЙСЫ</p>
                            </div>
                        </th>
                        <th style={{ textAlign: 'center',cursor:"pointer" }}>
                            <div>
                                <p>ИСПОЛНИТЕЛЬ</p>
                            </div>
                        </th>
                        <th style={{ cursor:"pointer" }}  onClick={()=>handleFilter("date")}>
                            <div>
                                <p>СРОКИ ЗАДАЧИ</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M15.5 9.5L12 6L8.5 9.5" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.5 14L12 17.5L8.5 14" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>

                        </th>
                        <th style={{ textAlign: 'center',cursor:"pointer" }} onClick={()=>handleFilter("prioritet")}>
                            <div>
                                <p> ПРИОРИТЕТ</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M15.5 9.5L12 6L8.5 9.5" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.5 14L12 17.5L8.5 14" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </th>
                        <th style={{ textAlign: 'center',cursor:"pointer" }} onClick={()=>handleFilter("status")}>
                            <div>
                                <p>СТАТУС</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M15.5 9.5L12 6L8.5 9.5" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.5 14L12 17.5L8.5 14" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </th>
                        <th style={{ textAlign: 'center',cursor:"pointer" }} onClick={()=>handleFilter("price")}>
                            <div>
                                <p>ЦЕНА</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M15.5 9.5L12 6L8.5 9.5" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.5 14L12 17.5L8.5 14" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>

                        </th>


                    </tr>
                </thead>
                <tbody >

                    {
                        getAllTaskRed.users?.map((item, index) => {
                            return <Tr item={item} itemID={item._id} update={update} allitem={item} data={data} handleGetNotify={handleGetNotify} index={index} onlineUsers={onlineUsers} key={index + item} />

                        })
                    }
                </tbody>
            </table>
        </div>
    )
}