import React, { useEffect } from 'react';
import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';






  export default function YandexMetric(params) {
    useEffect(()=>{
    
ym('hit', '/cart');
ym('reachGoal', 'whateverGoal', {awesomeParameter: 42});
    },[])
        return <div>
     
        <YMInitializer accounts={[96176063]} options={{webvisor: true}} version="2"/>
        

    </div>
  }