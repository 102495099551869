
import { useSelector, useDispatch } from 'react-redux'
import { editCompanyAction } from '../../../../../redux/reducer/editCompoany/action/action'
import { Input, Button, ButtonToolbar } from 'rsuite';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import Spinner from 'react-bootstrap/Spinner';
import { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { getMyCompany } from '../../../../../redux/reducer/getCompany/action/action';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getCompanyForChangeAPI } from '../../../../../redux/reducer/myCompanyAPI/action/action';

import { useToast } from '@chakra-ui/react'

export default function EditCompanyOptions({ items }) {

  const getDarkModoe = useSelector(darkModes => darkModes)
  const toast = useToast()

  const [loading, setLoading] = useState(false)

  const getCompany = useSelector(company => company)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [disabled, setDisabled] = useState(false)
  const [inn, SETinn] = useState("")








  const addCompany = (e) => {

    setLoading(true)
    setDisabled(true)
    dispatch(getMyCompany("/mycopany/getallcompany"))
    e.preventDefault()
    axios.patch("/mycopany/edit", {
      data: {
        inn: e.target.inn.value,
        sellersname: e.target.sellersname.value,
        indirecttax: e.target.indirecttax.checked,
        Legaladdress: e.target.Legaladdress.value,
        Checkingaccount: e.target.Checkingaccount.value,
        btc: e.target.btc.value,
        ognip: e.target.ognip.value,
        companyID: getCompany.getMyCompanyAPIReducer.company._id
      }


    })
      .then(function (response) {
        setLoading(false)
        setDisabled(false)


        swal(response.data.message);

        swal("Хорошая работа!", response.data.message, "success");
        dispatch(getMyCompany("/mycopany/getallcompany"))
        dispatch(getCompanyForChangeAPI("/changeapi/api"))
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false)
        setDisabled(false)
        toast({
          position: "top-right",
          title: 'Ошибка',
          description: error.response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })

      });
  }



  if (getCompany.getMyCompanyAPIReducer.company != "" && getCompany.getMyCompanyAPIReducer.company != undefined) {
    return (
      // <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "settingsContDark" : "settingsCont"}>
      //   <p className='editTitle'>Редактирование компании</p>
      //   <form onSubmit={addCompany}>
      //     <div className='child1'>
      //       <input placeholder="ИНН" name='inn' defaultValue={getCompany.getMyCompanyAPIReducer.company.inn} key={getCompany.getMyCompanyAPIReducer.company.inn + 1} />
      //       <input placeholder="Наименование продавца" name='sellersname' defaultValue={getCompany.getMyCompanyAPIReducer.company.sellersname} key={getCompany.getMyCompanyAPIReducer.company.inn + 2} />
      //     </div>
      //     <div className='child2'>
      //       <textarea as="textarea" rows={4} placeholder="Юридический адрес" name='Legaladdress' defaultValue={getCompany.getMyCompanyAPIReducer.company.Legaladdress} key={getCompany.getMyCompanyAPIReducer.company.Legaladdress + 4} />
      //     </div>
      //     <div className='child3'>
      //       <input placeholder="Расчётный счёт" name='Checkingaccount' defaultValue={getCompany.getMyCompanyAPIReducer.company.Checkingaccount} key={getCompany.getMyCompanyAPIReducer.company.Checkingaccount + 5} />
      //       <input placeholder="БИК" name='btc' defaultValue={getCompany.getMyCompanyAPIReducer.company.btc} key={getCompany.getMyCompanyAPIReducer.company.btc + 6} />
      //     </div>
      //     <div className='child4'>
      //       <input placeholder="ОГРН (ИП)" name='ognip' className='ognip' defaultValue={getCompany.getMyCompanyAPIReducer.company.ognip} key={getCompany.getMyCompanyAPIReducer.company.ognip + 7} />
      //       {/* <Checkbox  name='indirecttax' className='indirecttax'>С НДС</Checkbox> */}
      //       <div className='checkboxDiv'>

      //         <input type="checkbox" name='indirecttax' className='indirecttax' defaultChecked={getCompany.getMyCompanyAPIReducer.company.indirecttax} key={getCompany.getMyCompanyAPIReducer.company.indirecttax + 3}></input>
      //         <p> С НДС</p>
      //       </div>
      //       {loading ?
      //         <button variant="primary" disabled style={{ backgroundColor: "#5E83E0" }}>
      //           <Spinner
      //             as="span"
      //             animation="grow"
      //             size="sm"
      //             role="status"
      //             aria-hidden="true"
      //           />
      //           загрузка...
      //         </button>
      //         :
      //         <button colorScheme='blue' type='submit'  >Редактировать компанию</button>
      //       }
      //     </div>
      //   </form>
      //   {/* <button>Редактировать данные продавца</button> */}


      // </div>
      <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "settingsContDark" : "settingsCont"}>
        <form onSubmit={addCompany}>
          <div>
            <div>
              <p>Редактирование компании</p>
              <div className='child1'>
                <label htmlFor="">
                  <p>ИНН</p>
                  <input placeholder="ИНН" name='inn' defaultValue={getCompany.getMyCompanyAPIReducer.company.inn} key={getCompany.getMyCompanyAPIReducer.company.inn + 1} />
                </label>
                <label htmlFor="">
                  <p>Наименование компании</p>
                  <input placeholder="Наименование компании" name='sellersname' defaultValue={getCompany.getMyCompanyAPIReducer.company.sellersname} key={getCompany.getMyCompanyAPIReducer.company.inn + 2} />
                </label>
                <label htmlFor="">
                  <p>Юридический адрес</p>
                  <input placeholder="Юридический адрес" name='Legaladdress' defaultValue={getCompany.getMyCompanyAPIReducer.company.Legaladdress} key={getCompany.getMyCompanyAPIReducer.company.Legaladdress + 4} />
                </label>
                <div>
                  <label htmlFor="">
                    <p>Расчетный счет</p>
                    <input placeholder="Расчётный счёт" name='Checkingaccount' defaultValue={getCompany.getMyCompanyAPIReducer.company.Checkingaccount} key={getCompany.getMyCompanyAPIReducer.company.Checkingaccount + 5} />
                  </label>
                  <label htmlFor="">
                    <p>БИК</p>
                    <input placeholder="БИК" name='btc' defaultValue={getCompany.getMyCompanyAPIReducer.company.btc} key={getCompany.getMyCompanyAPIReducer.company.btc + 6} />
                  </label>
                  <label htmlFor="">
                    <p>ОГРН (ИП)</p>
                    <input placeholder="ОГРН (ИП)" name='ognip' className='ognip' defaultValue={getCompany.getMyCompanyAPIReducer.company.ognip} key={getCompany.getMyCompanyAPIReducer.company.ognip + 7} />
                  </label>
                </div>
              </div>
              <div className='child2'>
                <input type="checkbox" name='indirecttax' className='indirecttax' defaultChecked={getCompany.getMyCompanyAPIReducer.company.indirecttax} key={getCompany.getMyCompanyAPIReducer.company.indirecttax + 3}></input>
                <p> С НДС</p>
              </div>
              <div className='child4'>
                {loading ?
                  <button  disabled style={{opacity:"0.5"}}>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                        <path d="M1 19.5H10H19" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10.2198 4.32843L15.1696 9.27814M10.2198 4.32843L13.0483 1.5L17.998 6.44975L15.1696 9.27814L10.2198 4.32843ZM10.2198 4.32843L4.6132 9.93504C4.42567 10.1225 4.32031 10.3769 4.32031 10.6421V15.1776H8.85588C9.12108 15.1776 9.37538 15.0723 9.56298 14.8847L15.1696 9.27814L10.2198 4.32843Z" stroke="#3B79F6" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    <p>Редактировать компанию</p>
                  </button>
                  :
                  <button colorScheme='blue' type='submit'  >
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                        <path d="M1 19.5H10H19" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10.2198 4.32843L15.1696 9.27814M10.2198 4.32843L13.0483 1.5L17.998 6.44975L15.1696 9.27814L10.2198 4.32843ZM10.2198 4.32843L4.6132 9.93504C4.42567 10.1225 4.32031 10.3769 4.32031 10.6421V15.1776H8.85588C9.12108 15.1776 9.37538 15.0723 9.56298 14.8847L15.1696 9.27814L10.2198 4.32843Z" stroke="#3B79F6" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    <p>Редактировать компанию</p>
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  } else {
    return <h1>у вас нет компании</h1>
  }

}