import { NavLink } from "react-router-dom"
import "./navBottomLight.css"
import "./navBottomDark.css"
import { useSelector } from "react-redux"

export default function NavBottomForMobileAndPlamshet(params) {
    const getDarkModoe = useSelector(darkModes => darkModes);
    return (
        <div  className={getDarkModoe.ToggleDarkModeReducer.chekced?"navBottomDarkCont":"navBottomLightCont"}>
            <div>
                <NavLink to={"task"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none">
                            <path d="M6.69922 2.40039H17.6992" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1.5 2.2L2.3 2.99999L4.29999 1" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1.5 8.2L2.3 9L4.29999 7" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1.5 14.2L2.3 15L4.29999 13" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.69922 8.40039H17.6992" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.69922 14.4004H17.6992" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <p>Задачи</p>
                </NavLink>
                <NavLink to={"chat"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M5 10H15" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5 6H11" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1 18.2895V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V13C19 14.1046 18.1046 15 17 15H5.96125C5.35368 15 4.77906 15.2762 4.39951 15.7506L2.06852 18.6643C1.71421 19.1072 1 18.8567 1 18.2895Z" stroke="#919AA1" strokeWidth="1.5" />
                        </svg>
                    </div>
                    <p>Мессенджер</p>
                </NavLink>
                <NavLink to={"/settings/AddNewUser"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                            <path d="M8.75065 9.16732C10.3615 9.16732 11.6673 7.86148 11.6673 6.25065C11.6673 4.63982 10.3615 3.33398 8.75065 3.33398C7.13982 3.33398 5.83398 4.63982 5.83398 6.25065C5.83398 7.86148 7.13982 9.16732 8.75065 9.16732Z" stroke="#919AA1" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.62572 14.9993C11.3969 14.9993 12.8891 13.3644 11.6367 12.112V12.112C10.8709 11.3462 9.83232 10.916 8.74935 10.916C7.66638 10.916 6.62777 11.3462 5.862 12.112V12.112C4.6096 13.3644 6.10182 14.9993 7.87298 14.9993H9.62572Z" stroke="#919AA1" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13.4173 6.83333C13.9145 6.83333 14.4034 6.70625 14.8376 6.46415C15.2719 6.22205 15.637 5.87296 15.8984 5.45005C16.1598 5.02714 16.3087 4.54443 16.331 4.04777C16.3534 3.5511 16.2484 3.05697 16.0261 2.61229C15.8037 2.16762 15.4714 1.78715 15.0607 1.50703C14.6499 1.22691 14.1744 1.05643 13.6793 1.01179C13.1841 0.967138 12.6857 1.0498 12.2315 1.25193C11.7773 1.45406 11.3823 1.76894 11.084 2.16667" stroke="#919AA1" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13.9993 12.6673H14.2924C16.0635 12.6673 17.5558 11.0324 16.3034 9.77997V9.77997C15.5376 9.01419 14.499 8.58398 13.416 8.58398" stroke="#919AA1" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.41602 2.16667C6.11772 1.76894 5.7227 1.45406 5.26848 1.25193C4.81426 1.0498 4.31591 0.967138 3.82075 1.01179C3.32559 1.05643 2.85007 1.22691 2.43933 1.50703C2.02859 1.78715 1.69628 2.16762 1.47394 2.61229C1.2516 3.05697 1.14662 3.5511 1.16896 4.04777C1.19131 4.54443 1.34024 5.02714 1.60162 5.45005C1.86299 5.87296 2.22813 6.22205 2.66237 6.46415C3.0966 6.70625 3.58552 6.83333 4.08268 6.83333" stroke="#919AA1" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.08333 8.58398C3.00037 8.58398 1.96175 9.01419 1.19598 9.77997V9.77997C-0.0564186 11.0324 1.43581 12.6673 3.20697 12.6673H3.5" stroke="#919AA1" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <p>Сотрудники</p>
                </NavLink>
                {/* <NavLink to={"settings"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <path d="M10.5008 12.7008C11.992 12.7008 13.2008 11.492 13.2008 10.0008C13.2008 8.50957 11.992 7.30078 10.5008 7.30078C9.00957 7.30078 7.80078 8.50957 7.80078 10.0008C7.80078 11.492 9.00957 12.7008 10.5008 12.7008Z" stroke="#919AA1" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M17.3602 8.55586L16.3722 6.17032L17.7 4.6L15.9 2.8L14.3382 4.13466L11.902 3.13277L11.3418 1H9.5829L9.01419 3.16102L6.63397 4.16436L5.1 2.8L3.3 4.6L4.60803 6.20996L3.63525 8.60167L1.5 9.1V10.9L3.661 11.49L4.66417 13.8697L3.3 15.4L5.1 17.2L6.71204 15.8863L9.0573 16.8511L9.6 19H11.4L11.944 16.8519L14.3296 15.8639C14.7272 16.1482 15.9 17.2 15.9 17.2L17.7 15.4L16.3643 13.8245L17.3525 11.4382L19.4999 10.8795L19.5 9.1L17.3602 8.55586Z" stroke="#919AA1" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <p>Настройки</p>
                </NavLink> */}
                <NavLink to={"othermenu"}>
                    <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.16667 14H15.8333C16.2 14 16.5 13.7 16.5 13.3333C16.5 12.9667 16.2 12.6667 15.8333 12.6667H5.16667C4.8 12.6667 4.5 12.9667 4.5 13.3333C4.5 13.7 4.8 14 5.16667 14ZM5.16667 10.6667H15.8333C16.2 10.6667 16.5 10.3667 16.5 10C16.5 9.63333 16.2 9.33333 15.8333 9.33333H5.16667C4.8 9.33333 4.5 9.63333 4.5 10C4.5 10.3667 4.8 10.6667 5.16667 10.6667ZM4.5 6.66667C4.5 7.03333 4.8 7.33333 5.16667 7.33333H15.8333C16.2 7.33333 16.5 7.03333 16.5 6.66667C16.5 6.3 16.2 6 15.8333 6H5.16667C4.8 6 4.5 6.3 4.5 6.66667Z" fill="#585858" />
                        </svg>
                    </div>
                    <p className="otherMenuP">Еще</p>
                </NavLink>
            </div>
        </div>
    )
}