import axios from "axios"
import { useEffect, useState, useRef } from "react";
import "./uploadFile.css"
import { useSelector } from "react-redux";
import zipIcon from "./iconUpload/zipIcon.png"
import pdf from "./iconUpload/pdf.png"
import exel from "./iconUpload/exel.png"
import world from "./iconUpload/world.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const random = () => {
  return Math.random(Math.floor() * 100000)
}
export default function UploadFileTaskMenegerEdit({ handleChangeFile, files, setFile, handleDeleteFile, Taskfiles, setTaskfiles }) {


  const getDarkModoe = useSelector(darkModes => darkModes);
  const [currentSlide, setCurrentSlide] = useState(0);
  const modalRefFile = useRef(null);
  const mergedFiles = [...Taskfiles, ...files];




  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? mergedFiles.length - 1 : prev - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev === mergedFiles.length - 1 ? 0 : prev + 1));
  };



  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFile(prevFiles => [...prevFiles, ...selectedFiles]);
  };



  const handleDeleteLocal = (itemToDelete) => {
    console.log(itemToDelete.lastModified);

    setFile(prevArray => {
      // Фильтрация массива, оставляем только те элементы, которые не совпадают с itemToDelete по lastModified
      const newArray = prevArray.filter(item => item.lastModified !== itemToDelete.lastModified);
      return newArray; // Обновляем состояние с отфильтрованным массивом
    });
  };





  const openModalForShow = (index) => {
    setCurrentSlide(index)
    modalRefFile.current.style.display = "flex"
  }

  const closeModal = () => {

    modalRefFile.current.style.display = "none"
  }

  useEffect(() => {
    modalRefFile.current.style.display = "none";
  }, [])


  const handleDownload = (item) => {
    console.log(item, "itemitem");


    const fileURL = `/${item.filename}`; // Adjust path as needed
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = item.filename || 'downloaded_file';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

  }

  const downloadFileForLocal = (item) => {

    const fileURL = URL.createObjectURL(item);
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = item.name || 'downloaded_file';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(fileURL);

  };
  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "uploadFileDark" : "uploadFileLight"}>
      <div>
        <div>
          <label htmlFor="uploadEditTask">
            <p>Добавить файлы</p>
            <input type="file" id="uploadEditTask" style={{ display: "none" }} onChange={handleFileChange} multiple />
          </label>
        </div>
        <div>


          {
            mergedFiles.map((item, index) => {
              return (
                <div key={index} >
                  <div >
                    <div>
                      <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M7.5364 11.73C7.18492 11.3785 6.61508 11.3785 6.2636 11.73C5.91213 12.0814 5.91213 12.6513 6.2636 13.0027L9.53043 16.2696C9.8819 16.621 10.4517 16.621 10.8032 16.2696L18.5364 8.5364C18.8879 8.18492 18.8879 7.61508 18.5364 7.2636C18.1849 6.91213 17.6151 6.91213 17.2636 7.2636L10.1668 14.3604L7.5364 11.73Z" fill="#005FDB" />
                        </svg>
                        <p>{item.originalname || item.name}</p>
                      </div>
                      <div>
                        {
                          item?.filename ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" onClick={() => handleDeleteFile(index)}>
                              <path d="M11.1712 12L7.77521 8.60406C7.40826 8.23711 7.40826 7.64216 7.77521 7.27521C8.14216 6.90826 8.73711 6.90826 9.10406 7.27521L12.5 10.6712L15.8959 7.27521C16.2629 6.90826 16.8578 6.90826 17.2248 7.27521C17.5917 7.64216 17.5917 8.23711 17.2248 8.60406L13.8288 12L17.2248 15.3959C17.5917 15.7629 17.5917 16.3578 17.2248 16.7248C16.8578 17.0917 16.2629 17.0917 15.8959 16.7248L12.5 13.3288L9.10406 16.7248C8.73711 17.0917 8.14216 17.0917 7.77521 16.7248C7.40826 16.3578 7.40826 15.7629 7.77521 15.3959L11.1712 12Z" fill="#1C2E45" fill-opacity="0.6" />
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" onClick={() => handleDeleteLocal(item)}>
                              <path d="M11.1712 12L7.77521 8.60406C7.40826 8.23711 7.40826 7.64216 7.77521 7.27521C8.14216 6.90826 8.73711 6.90826 9.10406 7.27521L12.5 10.6712L15.8959 7.27521C16.2629 6.90826 16.8578 6.90826 17.2248 7.27521C17.5917 7.64216 17.5917 8.23711 17.2248 8.60406L13.8288 12L17.2248 15.3959C17.5917 15.7629 17.5917 16.3578 17.2248 16.7248C16.8578 17.0917 16.2629 17.0917 15.8959 16.7248L12.5 13.3288L9.10406 16.7248C8.73711 17.0917 8.14216 17.0917 7.77521 16.7248C7.40826 16.3578 7.40826 15.7629 7.77521 15.3959L11.1712 12Z" fill="#1C2E45" fill-opacity="0.6" />
                            </svg>
                        }

                      </div>
                    </div>


                    {
                      (() => {
                        if (item.type === "image/png" || item.type === "image/jpeg") {
                          return <img
                            src={URL.createObjectURL(item)}
                            alt="Uploaded"
                            onClick={() => openModalForShow(index)}
                          />
                        } else if (item.type === "video/mp4") {
                          return <video
                            src={URL.createObjectURL(item)}
                            alt="Uploaded"
                            onClick={() => openModalForShow(index)}

                          />
                        } else if (item.mimetype === "image/png" || item.mimetype === "image/jpeg") {
                          return <img
                            src={`/${item.filename}`}
                            alt="Uploaded"
                            onClick={() => openModalForShow(index)}
                          />
                        } else if (item.mimetype === "video/mp4") {
                          return <video
                            src={`/${item.filename}`}
                            alt="Uploaded"
                            onClick={() => openModalForShow(index)}

                          />
                        }
                        else {
                          return <img src={"https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"} alt=""  onClick={() => openModalForShow(index)}/>
                        }
                      })()
                    }


                  </div>
                </div>
              )
            })
          }


        </div>
      </div>
      <div>
        <div>
          <div className="myModalSliderUpload" ref={modalRefFile}>
            <div className="modal-contentSliderUpload">
              <div className="modal-header">
                {
                  (() => {
                    if (mergedFiles[currentSlide]?.type == "image/png" || mergedFiles[currentSlide]?.type == "image/jpeg") {
                      return <p>{mergedFiles[currentSlide]?.name}</p>
                    } else if (mergedFiles[currentSlide]?.type == "video/mp4") {
                      return <p>{mergedFiles[currentSlide]?.name}</p>
                    } else if (mergedFiles[currentSlide]?.mimetype == "image/png" || mergedFiles[currentSlide]?.mimetype == "image/jpeg") {
                      return <p>{mergedFiles[currentSlide]?.originalname}</p>
                    } else if (mergedFiles[currentSlide]?.mimetype == "video/mp4") {
                      return <p>{mergedFiles[currentSlide]?.originalname}</p>
                    }

                  })()
                }
                <div>
                  {
                    mergedFiles[currentSlide]?.mimetype ?
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none" onClick={() => handleDownload(mergedFiles[currentSlide])}>
                        <path d="M1 17H13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7 13L3.5 9.5M7 1V13V1ZM7 13L10.5 9.5L7 13Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      :
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none" onClick={() => downloadFileForLocal(mergedFiles[currentSlide])}>
                        <path d="M1 17H13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7 13L3.5 9.5M7 1V13V1ZM7 13L10.5 9.5L7 13Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                  }

                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" onClick={closeModal}>
                    <path d="M1.5 1L15.5 15M1.50003 15L8.50003 8L15.5 1" stroke="#767676" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                </div>
              </div>
              <div className="modal-body">
                <div className="slider">
                  {/* Объединение файлов из базы данных и локальных файлов */}


                  {
                    (() => {
                      if (mergedFiles[currentSlide]?.type == "image/png" || mergedFiles[currentSlide]?.type == "image/jpeg") {
                        return <img src={URL.createObjectURL(mergedFiles[currentSlide])} alt="" className="slide-item" />
                      } else if (mergedFiles[currentSlide]?.type == "video/mp4") {
                        return <video src={URL.createObjectURL(mergedFiles[currentSlide])} className="slide-item" controls></video>
                      } else if (mergedFiles[currentSlide]?.mimetype == "image/png" || mergedFiles[currentSlide]?.mimetype == "image/jpeg") {
                        return <img src={`/${mergedFiles[currentSlide]?.filename}`} alt={`Slide ${currentSlide + 1}`} className="slide-item" />
                      } else if (mergedFiles[currentSlide]?.mimetype == "video/mp4") {
                        return <video
                          src={`/${mergedFiles[currentSlide]?.filename}`} // Используем корректный путь
                          className="slide-item"
                          controls
                        ></video>
                      }
                      else {
                        return <img src={"https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"} alt="" />
                      }
                    })()
                  }

                  <button className="prev" onClick={handlePrevSlide}>&#10094;</button>
                  <button className="next" onClick={handleNextSlide}>&#10095;</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}