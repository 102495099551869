import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import EmojiPicker from 'emoji-picker-react';

import { Avatar, AvatarBadge } from "@chakra-ui/react";

import FileRenderComment from "../commentBox/fileRenderComment";

import UrlPreview from "../UrlPreview/urlPreview";

import { useDispatch, useSelector } from "react-redux";

import { fetchUsers } from "../../../../redux/reducer/postFetcReducer/action/action";
import SendMessageNoPerformerNotify from "../sendMessageNoPerformerNotify/sendMessageNoPerformerNotify";

import sockets from "../../../../socket/socket";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,

  PopoverBody,

  PopoverArrow,
  PopoverCloseButton,

} from '@chakra-ui/react'
import CommentPopover from "../commentPopover/commentPopover";


const socket = sockets

export default function CommentBoxChildEdit({ comments, setComments, index, isChild, allitem, editAllTask, update }) {


  const [isSend, setisSend] = useState(false)
  const [load, setLoad] = useState(false)
  const getDarkModoe = useSelector(darkModes => darkModes)
  const [commentsVal, setcommentsVal] = useState("");
  const [date, setDate] = useState("")
  const getmyinfo = useSelector(info => info)
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [images, setImages] = useState([]);
  const fileInputRef = useRef(null);
  const [toggle, setToggle] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [indexFile, setindexFile] = useState(0)
  const [deleteFiles, setdeleteFiles] = useState([])
  const [show, setShow] = useState(false)
  const [selectPerformer, setselectPerformer] = useState([])
  // если isSend верно и это родительский(isChild) элемент то отправляем на сервер
  let onlineUSers = useSelector(onlineUSers => onlineUSers)
  const [uploadProgress, setUploadProgress] = useState(0);

  const dispatch = useDispatch()
  const [text, setText] = useState('');
  const textAreaRef = useRef(null);
  // ==========================

  useEffect(() => {
    console.log(uploadProgress, "uploadProgress");
  }, [uploadProgress])
  useEffect(() => {
    dispatch(fetchUsers())
  }, [dispatch])

  // здесь отправляем на сервер
  useEffect(() => {
    if (isSend) {
      if (!isChild) {
        axios.post('/taskaddCommentsParent/api', {
          taskID: allitem._id,
          localComment: comments,
          editAllTask
        })
          .then(function (response) {
            console.log(response);
            setisSend(false)
            setLoad(false)
            update()
            console.log("gge");
          })
          .catch(function (error) {

            console.log(error);
          });
      } else {
        axios.post('/taskaddCommentsChild/api', {
          taskID: allitem._id,
          localComment: comments,
          editAllTask,
          index
        })
          .then(function (response) {
            console.log(response);
            setLoad(false)
            setisSend(false)

            update()
            sendCreatNotifyForCooments()
          })
          .catch(function (error) {

            console.log(error);
          });
      }
    }


  }, [isSend])


  async function uploadCommentFileForChild(tasks) {
    const updatedTasks = []; // Инициализируем пустой массив для обновленных задач

    for (const task of tasks) { // Проходим по каждой задаче в массиве задач
      const updatedComments = []; // Инициализируем пустой массив для обновленных комментариев

      for (const comment of task.comments) { // Проходим по каждому комментарию в задаче
        const formData = new FormData(); // Создаем новый объект FormData
        comment.images.forEach((file) => { // Для каждого файла изображения в комментарии
          console.log(file instanceof Blob);
          formData.append('images', file); // Добавляем файл в FormData с именем поля 'images'
        });

        try {
          const response = await axios.post('/uploadChildCommentAndGetForSaveImagePathAndComment/api', formData, { // Отправляем POST-запрос на сервер для загрузки файлов
            headers: {
              'Content-Type': 'multipart/form-data' // Устанавливаем заголовок для отправки форм-данных
            }
          });

          const imagePaths = response.data.paths; // Предполагаем, что сервер возвращает пути к загруженным файлам
          const blobs = comment.images.filter(file => !(file instanceof Blob)); // Keep existing images that are not blobs
          console.log(blobs, "blobs");

          const updatedComment = {
            ...comment, // Сохраняем остальные данные комментария
            images: [...imagePaths, ...blobs], // Обновляем массив изображений с путями к загруженным файлам

          };
          console.log(updatedComment, "updatedComment");

          updatedComments.push(updatedComment); // Добавляем обновленный комментарий в массив обновленных комментариев
        } catch (error) {
          console.error('Ошибка при загрузке файлов:', error); // Обрабатываем ошибки при загрузке файлов
        }
      }

      updatedTasks.push({
        ...task, // Сохраняем остальные данные задачи
        comments: updatedComments // Обновляем массив комментариев задачи
      });
    }

    return updatedTasks; // Возвращаем массив обновленных задач
  }





  const sendServerComment = () => {
    const formData = new FormData();
    //     // Добавляем данные, которые не являются файлами
    formData.append('taskID', allitem._id);
    formData.append('localComment', JSON.stringify({
      commentsVal,
      date,
      myid: getmyinfo.getMayInfoReducer.myinfo._id,
      tel: getmyinfo.getMayInfoReducer.myinfo.tel,
      name: getmyinfo.getMayInfoReducer.myinfo.name,
      img: getmyinfo.getMayInfoReducer.myinfo.img,
      email: getmyinfo.getMayInfoReducer.myinfo.email,
      rols: getmyinfo.getMayInfoReducer.myinfo.rols,
      images, // Временно пустой массив
      whoReadThisComment: []
    }));
    formData.append('editAllTask', editAllTask);
    formData.append('index', index);
    // Добавляем изображения
    files.forEach((file, idx) => {
      formData.append(`files`, file); // Имя поля должно совпадать с тем, что вы ожидаете на сервере
    });

    // Отправляем запрос на сервер
    axios.post('/taskaddCommentsChildEdit/api', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        console.log(response);
        setLoad(false);
        setisSend(false);
        sendCreatNotifyForCooments()
        update();
      })
      .catch(error => {
        console.error(error);
      });
  };

  // upload server and set image
  function resetFileInput() {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the value of the file input
    }
  }

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files); // Convert FileList to an array
    setFiles([...files, ...selectedFiles]);
    setImages([...images, ...selectedFiles])
    console.log(images, "imagesimagesimagesimages");

  };



  // add comment widht enter
  const addComment = (e) => {
    setDate(new Date().toLocaleString())
    if (e.key === "Enter") {

      setComments(comments => [...comments, {
        commentsVal,
        date,
        myid: getmyinfo.getMayInfoReducer.myinfo._id,
        tel: getmyinfo.getMayInfoReducer.myinfo.tel,
        name: getmyinfo.getMayInfoReducer.myinfo.name,
        img: getmyinfo.getMayInfoReducer.myinfo.img,
        email: getmyinfo.getMayInfoReducer.myinfo.email,
        rols: getmyinfo.getMayInfoReducer.myinfo.rols,
        images,
        whoReadThisComment: []
      }])
      sendServerComment()
      setcommentsVal("")
      setImages([])
      setFiles([])
      resetFileInput()

    }

  }
  // add comment widht button
  // здесь добавляем всё в состоянии setComments()


  // delete comment
  function deleteComment(itemComment) {
    axios.post('/deletCommentChild/api', {
      commentID: itemComment._id,
      comments,
      taskID: allitem._id
    })
      .then(function (response) {
        console.log(response);
        update();
      })
      .catch(function (error) {
        console.log(error);
      });


  }

  // set emojy setcommentsVal
  const handleEmojiClick = (emoji) => {
    setcommentsVal(val => val + emoji.emoji);
  };


  // dropdown toggle
  const handleShoweDropDown = (e) => {
    setToggle(!toggle);
  }
  const handleAll = (e) => {
    try {
      const parentElement = document.getElementById('dropdownCommentEmojy'); // Replace 'parentElement' with the actual parent element's ID
      if (parentElement != undefined && parentElement != null) {
        const isChild = parentElement?.contains(e.target);
        if (!isChild) {
          setToggle(false);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }
  // 
  window.addEventListener('click', handleAll);



  // get all Comment for edit


  const handleAllComments = (item, index) => {
    setcommentsVal("")
    setImages([])
    setcommentsVal(item.commentsVal)
    setImages(item.images)
    setIsEdit(true)
    setindexFile(index)
  }

  const enditChildUpdateComment = (updatedComments) => {
    const formData = new FormData();
    // Добавляем данные, которые не являются файлами
    formData.append('taskID', allitem._id);
    formData.append('editComment', JSON.stringify(updatedComments));
    formData.append('editAllTask', editAllTask);
    formData.append('subTaskIndex', index);
    formData.append('commentIndexChild', indexFile);

    // Добавляем изображения
    files.forEach((file, idx) => {
      formData.append(`files`, file); // Имя поля должно совпадать с тем, что вы ожидаете на сервере
    });

    // Отправляем запрос на сервер
    axios.post('/EdittaskaddCommentsChildEdit/api', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        // Вычисляем процент загрузки
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(progress); // Обновляем состояние прогресса загрузки
      }
    })
      .then(response => {
        console.log(response);
        setLoad(false);
        setisSend(false);
        setUploadProgress(0); // Сбрасываем прогресс после завершения загрузки
        update();
      })
      .catch(error => {
        console.error(error);
        setUploadProgress(0); // Сбрасываем прогресс в случае ошибки
      });
  };

  // submit all Comment for edit
  const submitEditComment = (e) => {
    if (e.key === "Enter") {
      canchelEdit()
      // Create a copy of the existing comments array
      const updatedComments = [...comments];
      // Update the specific comment object at indexFile
      updatedComments[indexFile] = {
        ...updatedComments[indexFile], // Preserve other properties
        commentsVal: commentsVal,
        images: images,
      };
      // Set the updated comments state
      setComments(updatedComments);
      enditChildUpdateComment(updatedComments[indexFile]);
      setcommentsVal("");
      setImages([]);
      setIsEdit(false);
      setFiles([]);

    }

  }



  // delet btn file
  const deleteFileInLocal = (e) => {
    setImages(prevState => prevState.filter((_, i) => i !== e))
    setdeleteFiles(prevState => [...prevState, images[e]])
    setFiles(prevState => prevState.filter((_, i) => i !== e))

  }

  const canchelEdit = () => {
    setImages(prev => [...prev, ...deleteFiles])
    setdeleteFiles([])
    setcommentsVal("")
    setImages([])
    setIsEdit(false)

  }


  function handleOnEnter(text) {
    console.log('enter', text)
  }
  // когда нажимается собачка(@) срабатывает этот событие
  function sendNotify(event) {

    if (event.key === '@') {
      setShow(true)
    } else {
      setShow(false)
    }
  }

  // здесь выбранный сотрудник добавляется в комментарии и добавляется State (setselectPerformer)
  const selectPerformerFunc = (e) => {
    if (e.name) {
      setcommentsVal(commentsVal + e.name + " ")
    } else {
      setcommentsVal(commentsVal + e.tel + " ")
    }

    setselectPerformer((prevSelectPerformer) => [...prevSelectPerformer, e]);
  }
  // этот события для того чтобы создать уведомление здесь проверяется в состоянии есть исполнитель или постановщик чтобы отправить ему уведомление Что есть новое сообщение для него
  function sendCreatNotifyForCooments() {
    if (selectPerformer.length > 0) {
      axios.post('/creatNotifyForComment/api', {
        localComment: {
          commentsVal,
          date,
          myid: getmyinfo.getMayInfoReducer.myinfo._id,
          tel: getmyinfo.getMayInfoReducer.myinfo.tel,
          name: getmyinfo.getMayInfoReducer.myinfo.name,
          img: getmyinfo.getMayInfoReducer.myinfo.img,
          email: getmyinfo.getMayInfoReducer.myinfo.email,
          rols: getmyinfo.getMayInfoReducer.myinfo.rols,
          images: [], // Временно пустой массив
          whoReadThisComment: []
        },
        isChild: true,
        selectPerformer,
        index,
        allitem
      })
        .then(function (response) {
          console.log(response);
          setselectPerformer([])
          socket.emit('getNotifyEmit');
        })
        .catch(function (error) {

          console.log(error);
        });
    }

  }

  // Это для того когда вы будете удалить в комментариях текст и там будет собачка тогда откроется модельный одну для выбора
  useEffect(() => {
    if (commentsVal[commentsVal.length - 1] == "@") {
      setShow(true)
    }


  }, [commentsVal])

  const addCommentBtn = (e) => {
    setDate(new Date().toLocaleString())
    setComments(comments => [...comments, {
      commentsVal,
      date,
      myid: getmyinfo.getMayInfoReducer.myinfo._id,
      tel: getmyinfo.getMayInfoReducer.myinfo.tel,
      name: getmyinfo.getMayInfoReducer.myinfo.name,
      img: getmyinfo.getMayInfoReducer.myinfo.img,
      email: getmyinfo.getMayInfoReducer.myinfo.email,
      rols: getmyinfo.getMayInfoReducer.myinfo.rols,
      images,
      whoReadThisComment: []
    }])
    sendServerComment()
    setcommentsVal("")
    setImages([])
    setFiles([])
    resetFileInput()
  }

  const editCommentBTN = (e) => {
    canchelEdit()
    // Create a copy of the existing comments array
    const updatedComments = [...comments];
    // Update the specific comment object at indexFile
    updatedComments[indexFile] = {
      ...updatedComments[indexFile], // Preserve other properties
      commentsVal: commentsVal,
      images: images,
    };
    // Set the updated comments state
    setComments(updatedComments);
    enditChildUpdateComment(updatedComments[indexFile]);
    setcommentsVal("");
    setImages([]);
    setIsEdit(false);
    setFiles([]);
  }

  function handleShow() {
    setShow(!show)
  
    
  }

  
  return (

    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "CommentAllContDark" : "CommentAllContLight"}>
      <div>
        <div >
          <div>
            <div>
              {uploadProgress > 0 && <div>Upload Progress: {uploadProgress}%</div>}
              {
                isEdit ?
                  <p>Редактируемое сообщение</p>
                  :
                  <p>Комментарии</p>
              }
              <div>
                <div>
                  <textarea name="" id="" onKeyDown={(e) => isEdit ? submitEditComment(e) : addComment(e)} value={commentsVal} ref={textAreaRef} onChange={(e) => [setcommentsVal(e.target.value), setDate(new Date().toLocaleString())]}></textarea>
                  <div className="editIamgeCont">

                    {
                      images?.map((item, index) => {
                        return <FileRenderComment files={item} showButton={true} deletFile={deleteFileInLocal} index={index} allFiles={images} key={index} isLocal={true} />
                      })
                    }

                  </div>
                </div>

                <div className="commentOptions">
                  <label htmlFor={`uploadFileForCommentEdit` + index} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                      <path d="M16.0899 9.00076L8.95575 16.135C8.08179 17.009 6.8964 17.5 5.66039 17.5C4.42437 17.5 3.23899 17.009 2.365 16.135C1.491 15.261 1 14.0756 1 12.8396C1 11.6035 1.491 10.4182 2.365 9.54417L9.49916 2.40999C10.0819 1.82733 10.8721 1.5 11.6961 1.5C12.5201 1.5 13.3104 1.82733 13.893 2.40999C14.4757 2.99266 14.8031 3.78292 14.8031 4.60692C14.8031 5.43093 14.4757 6.22119 13.893 6.80385L6.75109 13.938C6.45976 14.2294 6.06462 14.393 5.65263 14.393C5.24062 14.393 4.84549 14.2294 4.55416 13.938C4.26283 13.6467 4.09916 13.2516 4.09916 12.8396C4.09916 12.4276 4.26283 12.0324 4.55416 11.7411L11.1449 5.15809" stroke="#767676" stroke-width="1.16445" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <input type="file" id={`uploadFileForCommentEdit` + index} style={{ display: "none" }} multiple onChange={handleImageChange} ref={fileInputRef} />
                  </label>
                  <div>
                    <Popover placement='auto'>
                      <PopoverTrigger>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                          <path d="M9.5 17.5C5.08172 17.5 1.5 13.9182 1.5 9.5C1.5 5.08172 5.08172 1.5 9.5 1.5C13.9182 1.5 17.5 5.08172 17.5 9.5C17.5 13.9182 13.9182 17.5 9.5 17.5Z" stroke="#767676" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M13.0984 11.5C13.0984 11.5 11.8984 13.1 9.49844 13.1C7.09844 13.1 5.89844 11.5 5.89844 11.5" stroke="#767676" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M12.2984 7.09688C12.0776 7.09688 11.8984 6.91779 11.8984 6.69688C11.8984 6.47596 12.0776 6.29688 12.2984 6.29688C12.5193 6.29688 12.6984 6.47596 12.6984 6.69688C12.6984 6.91779 12.5193 7.09688 12.2984 7.09688Z" fill="#767676" stroke="#767676" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6.69688 7.09688C6.47596 7.09688 6.29688 6.91779 6.29688 6.69688C6.29688 6.47596 6.47596 6.29688 6.69688 6.29688C6.91779 6.29688 7.09688 6.47596 7.09688 6.69688C7.09688 6.91779 6.91779 7.09688 6.69688 7.09688Z" fill="#767676" stroke="#767676" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </PopoverTrigger>
                      <PopoverContent width={"auto"} padding={0} className="smileCommentContent">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody padding={0}> <EmojiPicker className="smileCommentContentEmoji" onEmojiClick={handleEmojiClick} allowExpandReactions={true} /></PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </div>
                  {/* <ToWhomToSend show={show} commentsVal={commentsVal} textAreaRef={textAreaRef} setShow={setShow}/> */}
                  <SendMessageNoPerformerNotify setShow={setShow} show={show} selectPerformerFunc={selectPerformerFunc} perfromer={allitem?.alltask[0]?.child[index]?.performer} allitem={allitem} handleShow={handleShow}/>
                  <div onClick={(e) => isEdit ? editCommentBTN(e) : addCommentBtn(e)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M1.62 14.1095L15.58 8.12547C16.228 7.84547 16.228 6.93347 15.58 6.65347L1.62 0.669467C1.092 0.437467 0.508 0.829467 0.508 1.39747L0.5 5.08547C0.5 5.48547 0.796 5.82947 1.196 5.87747L12.5 7.38947L1.196 8.89347C0.796 8.94947 0.5 9.29347 0.5 9.69347L0.508 13.3815C0.508 13.9495 1.092 14.3415 1.62 14.1095Z" fill=" #3B79F6" />
                    </svg>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="commentScrollDiv">

            {
              comments?.map((item, index) => {
                return (
                  <div key={index} className="CommentListCont">
                    <div className="CommentItem">
                      <div>
                        {
                          onlineUSers.getOnlineUserViaRouterReducer.users.data?.includes(item.myid) ?
                            <Avatar size="md" name="Christian Nwamba" src={`/${item.img}`} zIndex={0}>
                              <AvatarBadge boxSize='0.9em' bg='green.500' zIndex={0} />
                            </Avatar>
                            :
                            <Avatar size="md" name="Christian Nwamba" src={`/${item.img}`} zIndex={0} />
                        }
                        <div className="firstDiv">
                          <div>
                            <div>
                              <p>{item.name}</p>
                              <p className="commentItemTime">{item.date} PM</p>
                              {item?.whoReadThisComment?.length > 0 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                                  <path d="M6.5 10L9.14286 13L17 5" stroke="#3B79F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M1 9L5 13L15 3" stroke="#3B79F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M5 13L9 17L19 7" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              }
                            </div>
                            <div>
                              <UrlPreview url={`${item.commentsVal}`} />
                            </div>
                          </div>
                        </div>
                        <div className="lastDiv">
                          <CommentPopover deleteComment={() => deleteComment(item)} handleAllComments={handleAllComments} item={item} index={index} />


                        </div>
                      </div>
                    </div>
                    <div className="localFilesRender">
                      {
                        item.images?.map((itemChild, indexChild) => {
                          return <FileRenderComment files={itemChild} showButton={false} deletFile={deleteFileInLocal} index={indexChild} allFiles={item.images} isLocal={false} />
                        })
                      }
                    </div>
                  </div>
                )

              })

            }



          </div>
        </div>

      </div>

    </div>
  )
}