
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ModalEditTask from '../modalEditTask/modalEditTask';
import { useToast } from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import TaskEdit from '../taskEdit/taskEdit';
import "./getCurrentTask.css"

import sockets from '../../../../socket/socket';
const socket = sockets;

export default function GetcurrentTask(params) {
  let { id } = useParams();
  const [item, setItem] = useState()
  let getCompanyID = useSelector(compID => compID.checkedSessionReducer)
  const [adminInfo, setadminInfo] = useState("")
  const [load, setload] = useState(false)
  const toast = useToast()
  const navigate = useNavigate();
  const changeCheckedCompany = async (checked) => {
    try {
      const response = await axios.get('/mycopany/changechecked', {
        params: { checked },
        withCredentials: true, // Это нужно, если вы используете сессии
      });
      console.log(response.data); // { message: "ok" }
    } catch (error) {
      if (error.response) {
        console.error(error.response.data); // { message: "кодовая ошибка" }
      } else {
        console.error(error.message);
      }
    }
  };

  useEffect(() => {
    axios.post('/findTaskByUrl', {
      taskID: id,
      child: false
    })
      .then(function (response) {
        console.log(response.data.data.companyID, "getCurrentData");
       
        setItem(response.data.data)
       setadminInfo(response.data.adminIfno)
       changeCheckedCompany(response.data.data.companyID)
      })
      .catch(function (error) {
       
        toast({
          position: "top",
          title: 'Задача не найдена или удалена',
          description: "Задача не найдена или удалена",
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
        setTimeout(() => {
          navigate('/task'); // Укажите нужный URL для перенаправления
        }, 2000);

      });
  }, [])

  useEffect(() => {


    // update all task for my data
    socket.on('getAllTaskANDupdate', () => {
      console.log("socket update : getAllTaskANDupdate");
      setload(true)
      console.log("UPDATEtASK");


      axios.post('/findTaskByUrl', {
        taskID: id,
        child: false
      })
        .then(function (response) {
          setItem(response.data.data)
          setadminInfo(response.data.adminIfno)
        })
        .catch(function (error) {
          alert("error")
          toast({
            position: "top",
            title: 'Задача не найдена или удалена',
            description: "Задача не найдена или удалена",
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
          setTimeout(() => {
            navigate('/task'); // Укажите нужный URL для перенаправления
          }, 2000);

        });

    });


    return () => {

      socket.off('getAllTaskANDupdate');

    };
  }, []);

  const handleGetNotify = () => {
    socket.emit('getNotifyEmit');
  }
  function update(params) {
    socket.emit('getallTaskforUpdateServer', { room: getCompanyID.checkedSession });
  }

  if (item) {
    return <TaskEdit editAllTask={item.alltask} itemID={item._id} update={update} allitem={item} data={item} handleGetNotify={handleGetNotify} key={"index"} adminInfo={adminInfo} />
  }

}