import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import { useEffect, useState, useRef } from 'react'
export default function FileRenderCommentCreat({ files, isChild, showButton, deletFile, index, allFiles, isEdit }) {

    const [slideIndex, setSlideIndex] = useState(index);
    const modalRefFile = useRef(null);

    const openModalForShow = () => {
        modalRefFile.current.style.display = "flex"
    }

    const closeModal = () => {

        modalRefFile.current.style.display = "none"
    }

    useEffect(() => {
        modalRefFile.current.style.display = "none";
        console.log(allFiles[slideIndex])
    }, [])


    const next = () => {
        if (slideIndex < allFiles.length - 1) {
            setSlideIndex((prevIndex) => prevIndex + 1);
        }
    }

    const prev = () => {
        if (slideIndex > 0) {
            setSlideIndex((prevIndex) => prevIndex - 1);
        }
    }
    const handleDownload = (item) => {
    
        const fileURL = `/${item.filename}`; // Adjust path as needed
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = item.filename || 'downloaded_file';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      
    }

    const downloadFileForLocal = (item) => {
    
        const fileURL = URL.createObjectURL(item);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = item.name || 'downloaded_file';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
      
      };



    return (
        <div className="FilRenderCreat">
            <div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M7.5364 11.73C7.18492 11.3785 6.61508 11.3785 6.2636 11.73C5.91213 12.0814 5.91213 12.6513 6.2636 13.0027L9.53043 16.2696C9.8819 16.621 10.4517 16.621 10.8032 16.2696L18.5364 8.5364C18.8879 8.18492 18.8879 7.61508 18.5364 7.2636C18.1849 6.91213 17.6151 6.91213 17.2636 7.2636L10.1668 14.3604L7.5364 11.73Z" fill="#005FDB" />
                    </svg>
                    <p>{files.name}</p>
                </div>
                {
                    showButton ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" onClick={(e) => deletFile(index)}>
                            <path d="M11.1712 12L7.77521 8.60406C7.40826 8.23711 7.40826 7.64216 7.77521 7.27521C8.14216 6.90826 8.73711 6.90826 9.10406 7.27521L12.5 10.6712L15.8959 7.27521C16.2629 6.90826 16.8578 6.90826 17.2248 7.27521C17.5917 7.64216 17.5917 8.23711 17.2248 8.60406L13.8288 12L17.2248 15.3959C17.5917 15.7629 17.5917 16.3578 17.2248 16.7248C16.8578 17.0917 16.2629 17.0917 15.8959 16.7248L12.5 13.3288L9.10406 16.7248C8.73711 17.0917 8.14216 17.0917 7.77521 16.7248C7.40826 16.3578 7.40826 15.7629 7.77521 15.3959L11.1712 12Z" fill="#1C2E45" fill-opacity="0.6" />
                        </svg>
                        :
                        ""
                }

            </div>
            {
                (() => {
                    if (files.type == "image/png" || files.type == "image/jpeg") {
                        return <img src={URL.createObjectURL(files)} alt="" onClick={openModalForShow} />
                    } else if (files.type == "video/mp4") {
                        return <video src={URL.createObjectURL(files)} onClick={openModalForShow}></video>
                    } else {
                        return <img src={"https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"} alt="" onClick={openModalForShow} />
                    }
                })()
            }

            <div>
                <div>
                    <div className="myModalSliderComment" ref={modalRefFile}>
                        <div className="modal-contentSliderComment">
                            <div className="modal-header">


                                {
                                    (() => {
                                        if (files.type == "image/png" || files.type == "image/jpeg") {
                                            return <p>{allFiles[slideIndex].name}</p>
                                        } else if (files.type == "video/mp4") {
                                            return <p>{allFiles[slideIndex].name}</p>
                                        } else if (files.mimetype == "image/png" || files.mimetype == "image/jpeg") {
                                            return <p>{allFiles[slideIndex].originalname}</p>
                                        } else if (files.mimetype == "video/mp4") {
                                            return <p>{allFiles[slideIndex].originalname}</p>
                                        }
                                        else {
                                            return <img src={"https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"} alt="" onClick={openModalForShow} />
                                        }
                                    })()
                                }

                                <div>
                                {
                                           files.mimetype?
                                           <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none" onClick={()=>handleDownload(allFiles[slideIndex].filename)}>
                                           <path d="M1 17H13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                           <path d="M7 13L3.5 9.5M7 1V13V1ZM7 13L10.5 9.5L7 13Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                       </svg>
                                           :
                                           ""
                                        }

{
                                           files.type?
                                           <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none" onClick={()=>downloadFileForLocal(files)}>
                                           <path d="M1 17H13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                           <path d="M7 13L3.5 9.5M7 1V13V1ZM7 13L10.5 9.5L7 13Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                       </svg>
                                           :
                                           ""
                                        }
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" onClick={closeModal}>
                                        <path d="M1.5 1L15.5 15M1.50003 15L8.50003 8L15.5 1" stroke="#767676" stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="slider">


                                    {
                                        (() => {
                                            if (allFiles[slideIndex].type == "image/png" || allFiles[slideIndex].type == "image/jpeg") {
                                                return <img src={URL.createObjectURL(allFiles[slideIndex])} alt="" onClick={openModalForShow} className="slide-item"/>
                                            } else if (allFiles[slideIndex].type == "video/mp4") {
                                                return <video src={URL.createObjectURL(allFiles[slideIndex])} onClick={openModalForShow} className="slide-item"></video>
                                            } else if (allFiles[slideIndex].mimetype == "image/png" || allFiles[slideIndex].mimetype == "image/jpeg") {
                                                return <img src={`/${allFiles[slideIndex].filename}`} alt={`Slide ${slideIndex + 1}`} className="slide-item" onClick={openModalForShow} />
                                            } else if (allFiles[slideIndex].mimetype == "video/mp4") {
                                                return <video src={`/${files.filename}`} onClick={openModalForShow} className="slide-item"></video>
                                            }
                                            else {
                                                return <img src={"https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"} alt="" onClick={openModalForShow} />
                                            }
                                        })()
                                    }
                                    <button className="prev" onClick={() => prev("prev")}>&#10094;</button>
                                    <button className="next" onClick={() => next("next")}>&#10095;</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}