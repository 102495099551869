
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import "./taskmeneger.css"
import "./taskmenegerDark.css"


import axios from 'axios';

import TaskTable from './tableTask/tableTask';
import { getAllTask } from '../../../redux/reducer/getTaskall/action/action';
import sockets from '../../../socket/socket';
import ForMobileAndTable from './tableTask/ForMobileAndTable/forMobileAndTable';

const socket = sockets;



export default function TaskMeneger(params) {

  const [message, setMessage] = useState('');
  const [receivedMessages, setReceivedMessages] = useState([])
  const [delLoad, setDelload] = useState(false)
  const [load, setload] = useState(false)
  const [onlineUsers, setOnlineUsers] = useState([]);
  const getDarkModoe = useSelector(darkModes => darkModes);

  const dispatch = useDispatch()
  let getCompanyID = useSelector(compID => compID.checkedSessionReducer)
  let getmyinfo = useSelector(compID => compID.getMayInfoReducer)
  const getAllTaskRed = useSelector(compID => compID.getAllTaskReducer)
  let onlineUSers = useSelector(compID => compID.AllOnlineUsersReducer)



  useEffect(() => {
    // push my id for online 
    // socket.emit('pushmyid', getmyinfo.myinfo._id);

    // get all task update socket
    socket.on('newMessage', (message) => {


      dispatch(getAllTask("/getTaskfinded/api"))
    });
    // update all task for my data
    socket.on('getAllTaskANDupdate', () => {

      dispatch(getAllTask("/getTaskfinded/api"))


    });

    socket.on('data-updated', (uupdate) => {
      update()
    });

    return () => {
      socket.off('newMessage');
      // socket.off('online');
      // socket.off('disconnect');
      socket.off('notification');
      // socket.off('getNotify');
      socket.off('getAllTaskANDupdate');
      socket.off('data-updated');

    };
  }, []);




  useEffect(() => {
    dispatch(getAllTask("/getTaskfinded/api"));

    // Обработчики событий сокета
    const handleNewMessage = (message) => {
      dispatch(getAllTask("/getTaskfinded/api"));
    };

    const handleGetAllTaskANDupdate = () => {
      dispatch(getAllTask("/getTaskfinded/api"));
    };

    const handleDataUpdated = () => {
      update();
    };

    socket.on('newMessage', handleNewMessage);
    socket.on('getAllTaskANDupdate', handleGetAllTaskANDupdate);
    socket.on('data-updated', handleDataUpdated);

    return () => {
      // Удаляем обработчики при размонтировании
      socket.off('newMessage', handleNewMessage);
      socket.off('getAllTaskANDupdate', handleGetAllTaskANDupdate);
      socket.off('data-updated', handleDataUpdated);
    };
  }, [dispatch]);

  useEffect(() => {
    setReceivedMessages([]);
    socket.emit('getAllTask', { companyIDS: getCompanyID.checkedSession });
  }, [getCompanyID.checkedSession]);

  const sendMessage = (task, perfomerID, metka) => {
    perfomerID.push(getmyinfo.myinfo);
    axios.post('/createTask/api', {
      CompanyID: getCompanyID.checkedSession,
      message,
      task,
      creatorTask: getmyinfo.myinfo._id,
      perfomerID,
      allmetka: metka
    })
      .then(() => {
        socket.emit('message', { room: getCompanyID.checkedSession, message, task });
      })
      .catch((error) => {
        console.log(error);
      });

    setMessage('');
  };

  const update = () => {
    socket.emit('message', { room: getCompanyID.checkedSession });
  };

  const handleDelet = (card) => {
    setDelload(true);
    axios.post('/taskmeneger/delete/api', {
      companyID: card.companyID,
      cardID: card._id
    })
      .then(() => {
        socket.emit('getAllTask', { companyIDS: getCompanyID.checkedSession });
        socket.emit('getNotifyEmit');
        socket.emit('message', { room: getCompanyID.checkedSession });
        setDelload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetNotify = () => {
    socket.emit('getNotifyEmit');
  };

  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "taskMenegerContDark" : "taskMenegerContLight"}>

      <Helmet>
        <title>Трекер задач</title>
        <meta name="description" content="Luchniy Tracker задач - надежное решение для управления задачами вашего бизнеса. Наш Tracker задач предлагает интуитивно понятный интерфейс и мощные инструменты для эффективного планирования, отслеживания и управления задачами. С нашим Tracker задач вы можете легко организовывать работу своей команды, устанавливать сроки выполнения задач и отслеживать их выполнение в реальном времени.
Наш Tracker задач обеспечивает полную прозрачность и контроль над процессом выполнения задач, что помогает оптимизировать производительность и достичь поставленных целей. Благодаря гибким настройкам и интеграциям, наш Tracker задач легко адаптируется под потребности вашего бизнеса.
Надежный, масштабируемый и безопасный - наш Tracker задач является незаменимым инструментом для успешного ведения вашего бизнеса. Не откладывайте управление задачами на потом - начните использовать наш Tracker задач уже сегодня и повысьте эффективность работы вашей команды! Этот текст содержит ключевые слова, описывающие ваш продукт (Tracker задач), а также его преимущества для бизнеса. Он информативен и привлекает внимание, что важно для SEO"

        />
      </Helmet>


      < TaskTable receivedMessages={receivedMessages} handleDelet={handleDelet} delLoad={delLoad} update={update} load={load} handleGetNotify={handleGetNotify} onlineUsers={onlineUSers.allOnlineUsers} sendMessage={sendMessage} />


    </div>
  );

}



