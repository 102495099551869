var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
var isgmail = /gmail/g;
var ismail = /mail/g;
var passwordVal=  /^[A-Za-z]\w{7,14}$/;
export function validation(str) {
                console.log(str,"valid");
            if (str.value.match(mailformat) || str.value.match(ismail)) {
                let findbeforDot = str.value.split(".")
                    if(findbeforDot[findbeforDot.length-1] == "com" || "ru" && str.value.match(isgmail)){
                        
                        return true;
                    }
               
            }else{
              
                return false;
            }
}


export  function passwordValidation(str) {
        if (str.value.match(passwordVal)) return true
        return false;
}